<div class="modal-header">
    <h4 class="modal-title">{{accion}} items</h4>
    <button type="button" class="close" aria-label="Close" (click)="onDismiss('Cross click')">
        <span aria-hidden="true">x</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="formItems" autocomplete="off">
        <div class="form-row">
            <div class="form-group col-md-12">
                <label for="inputNombre">Nombre</label>
                <input type="text" name="nombre" formControlName="nombre" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }" class="form-control input-sm"
                    placeholder="Nombre">
                    <small class="form-text text-muted" *ngIf="f.nombre.hasError('maxlength')">Excede el límite de caracteres permitidos</small>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-4">
                <label for="inputproyecto">Tipo</label>
                <select class="custom-select" formControlName="tipo">
                    <option selected>seleccione...</option>
                    <option *ngFor="let item of tipos ; let i = index" [value]="tipos[i].id">
                        {{tipos[i].nombre}}
                    </option>
                </select>

            </div>
        </div>
      

        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="onClose(false)">Cerrar</button>
            <button id="btnGuardar" type="submit" *ngIf="!isReadOnly" class="btn btn-primary"
                (click)="crearItem(formItems.value)">Guardar</button>

        </div>
    </form>
</div>