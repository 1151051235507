import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { DashboardService } from 'src/app/services/dashboard.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Barra } from 'src/app/modules/barra';
import { HelpComponent } from '../help/help.component';
import { ayuda } from 'src/app/modules/global-constants';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

declare var $:any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styles: []
})
export class HomeComponent implements OnInit {
  [x: string]: any;
  Indicadores:[];
  Barras: Barra[] ;
  ingreso:  any=[];
  egreso:  any=[]
 errors:any;
  

  
  constructor(private dashboardService:DashboardService, private router:Router,private modalService: NgbModal,public activeModal: NgbActiveModal) { }

  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true
  };
  public barChartLabels = ['ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO', 'AGOSTO','SEPTIEMBRE','OCTUBRE','NOVIEMBRE','DICIEMBRE'];
  public barChartType = 'bar';
  public barChartLegend = true;
  public barChartData = [
    {data: [0, 0, 0, 0, 0, 0, 0,0,0,0,0,0], label: 'Egresos'},
    {data: [0, 0, 0, 0, 0, 0, 0,0,0,0,0,0], label: 'Ingresos'}
  ];
  
  ngOnInit() {
    $('[data-toggle="popover"]').popover();
    this.listIndicador();
    this.listGraficoBarra();

  }

  listIndicador() {
    this.dashboardService.listIndicador()
      .subscribe(data => {

        this.Indicadores = data.result;
      
        console.log(this.Indicadores);
        /*302 token expirado*/
        if (data.status == 302) {
          this.router.navigate(['login']);
          return;
        }
      },
       error => {
         this.router.navigate(['login']);
         return;
       },
     
      );
  }

  listGraficoBarra () {
    this.dashboardService.listGraficoBarra()
      .subscribe(data => {
        this.Barras = data.result;
   
        //this.selectMeses.id = this.Meses[0];
        console.log(this.Barras);
        /*302 token expirado*/
        if (data.status == 302) {
          this.router.navigate(['login']);
          return;
        }
        else {
          this.setValuesToChart()
        }
      },
      error => {
        this.router.navigate(['login']);
        return;
      },
      );
  }

  public setValuesToChart(): void{
     
    for(let i=0; i<this.Barras.length; i++){
   
      this.egreso.push(this.Barras[i].egreso);
      this.ingreso.push(this.Barras[i].ingreso);
        
    }
    this.barChartData[1].data=this.ingreso;
    this.barChartData[0].data=this.egreso;
    
    this.barChartData = this.barChartData.slice();
  }

  onModalHelp(): void {
    const modalRef = this.modalService.open(HelpComponent, { size: 'md', backdrop: 'static' });
    modalRef.componentInstance.id = ayuda.tablero
      
    modalRef.result.then((result) => {
        

    }).catch((result) => {
     
    });
  }
 
  }


