<div class="modal-header">
    <h4 class="modal-title">{{accion}} noticia</h4>
    <button type="button" class="close" aria-label="Close" (click)="onDismiss('Cross click')">
        <span aria-hidden="true">x</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="formNoticia" autocomplete="off">
        <div class="form-row">
            <div class="form-group col-md-12">
              <label for="inputTitulo">Título</label>
              <textarea  name="titulo" formControlName="titulo" [ngClass]="{ 'is-invalid': submitted && f.titulo.errors }" class="form-control input-sm" placeholder="Título"></textarea>
                <small class="form-text text-muted" *ngIf="f.titulo.hasError('maxlength')">Excede el límite de caracteres permitidos</small>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="inputCuerpo">Cuerpo</label>
              <textarea  rows="5" name="cuerpo" formControlName="cuerpo" [ngClass]="{ 'is-invalid': submitted && f.cuerpo.errors }" class="form-control input-sm" placeholder="Cuerpo"></textarea>
                <small class="form-text text-muted" *ngIf="f.cuerpo.hasError('maxlength')">Excede el límite de caracteres permitidos</small>
            </div>
          </div>
        <div class="form-row">
         
          <div class="form-group col-md-5">
            <label for="inputDesde">F. Desde</label>
            <div class="input-group">
               <input formControlName="desde"  class="form-control" [ngClass]="{ 'is-invalid': submitted && f.desde.errors }" placeholder="yyyy-mm-dd" name="desde"  ngbDatepicker #desde="ngbDatepicker">
              <div class="input-group-append">
                <button class="btn btn-secondary2 calendar" *ngIf="!isReadOnly"  (click)="desde.toggle()" type="button"></button>
              </div>
            </div>
          </div>
          <div class="form-group col-md-5">
            <label for="inputHasta">F. Hasta</label>
            <div class="input-group">
               <input formControlName="hasta"  class="form-control" [ngClass]="{ 'is-invalid': submitted && f.hasta.errors }" placeholder="yyyy-mm-dd" name="hasta"  ngbDatepicker #hasta="ngbDatepicker">
              <div class="input-group-append">
                <button class="btn btn-secondary2 calendar" *ngIf="!isReadOnly"  (click)="hasta.toggle()" type="button"></button>
              </div>
            </div>
          </div>
        </div>
      
       
      
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="onClose(false)" >Cerrar</button>
            <button id="btnGuardar" type="submit" *ngIf="!isReadOnly"  class="btn btn-primary" (click)="crearNoticia(formNoticia.value)">Guardar</button>
           
        </div>
      </form>
</div>

