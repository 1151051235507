import { Injectable, Output, EventEmitter } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '../modules/apiresponse';

import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

import { Session } from '../modules/session';
import { GlobalConstants } from '../modules/global-constants';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  redirectUrl: string;
  
  baseUrl:string = GlobalConstants.apiURL;
  
  
  constructor(private httpClient: HttpClient, private router:Router) {
    this.currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
   }
 
   public get currentUserValue(): Session {
    return this.currentUserSubject.value;
}
  login(loginPayload): Observable<ApiResponse> {
    return this.httpClient.post<ApiResponse>(this.baseUrl + '/login_general.php', loginPayload)
         .pipe(map(data => {
         
          this.currentUserSubject.next(data.result);
             return data;
         }));
}

logout(): void {
 
  this.router.navigate(['/login']);

}


}
