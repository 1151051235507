import { Injectable, Output, EventEmitter } from '@angular/core';
import { map, tap, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ApiResponse } from '../modules/apiresponse';
import { Socio } from '../modules/socio';
import { AuthService } from '../auth/auth.service';
import { GlobalConstants } from '../modules/global-constants';


@Injectable({
  providedIn: 'root'
})

export class SocioService {
  redirectUrl: string;
  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };
  baseUrl:string = GlobalConstants.apiURL;
  
  @Output() getLoggedInName: EventEmitter<any> = new EventEmitter();
  currentUser = this.dataService.currentUserValue;
  constructor(private httpClient: HttpClient, private dataService: AuthService ) { }

  public listSocios() : Observable<ApiResponse>{
    return this.httpClient.get<ApiResponse>(this.baseUrl +'/listSocios.php');
  }

  public listSociosShort() : Observable<ApiResponse>{
    return this.httpClient.get<ApiResponse>(this.baseUrl +'/listSociosShort.php');
  }


  public listIdSocios(Id: number) : Observable<ApiResponse>{
    return this.httpClient.get<ApiResponse>(this.baseUrl +'/listIdSocio.php?id='+Id);
  }
  public createSocio (socio: Socio): Observable<ApiResponse> {
      return this.httpClient.post<ApiResponse>(this.baseUrl +'/createSocio.php', socio)
     
  }

  deleteSocio(id: number): Observable<ApiResponse> {
    return this.httpClient.get<ApiResponse>(this.baseUrl +'/deleteSocio.php?id='+id+'&usuario='+this.currentUser.user[0].username);
  }

}
