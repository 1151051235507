import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '../modules/apiresponse';
import { Observable } from 'rxjs';
import { GlobalConstants } from '../modules/global-constants';

@Injectable({
  providedIn: 'root'
})
export class TipoService {
  baseUrl:string = GlobalConstants.apiURL;
  constructor(private httpClient: HttpClient ) { }

  public listIdTipoMovimiento(id:number) : Observable<ApiResponse>{
    return this.httpClient.get<ApiResponse>(this.baseUrl +'/listIdTipoMovimiento.php?id='+id);
  }

  public listTipoMovimiento() : Observable<ApiResponse>{
    return this.httpClient.get<ApiResponse>(this.baseUrl +'/listTipoMov.php');
  }

  public listRolSocio() : Observable<ApiResponse>{
    return this.httpClient.get<ApiResponse>(this.baseUrl +'/listRolSocio.php');
  }

  public listMeses() : Observable<ApiResponse>{
    return this.httpClient.get<ApiResponse>(this.baseUrl +'/listMeses.php');
  }
}
