import { Component, OnInit } from '@angular/core';
import { Noticia } from '../modules/noticia';
import { Router } from '@angular/router';
import { NoticiaService } from '../services/noticia.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateNoticiaComponent } from './create-noticia/create-noticia.component';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../auth/auth.service';
import { HelpComponent } from '../components/shared/help/help.component';
import { ayuda } from '../modules/global-constants';

@Component({
  selector: 'app-noticias',
  templateUrl: './noticias.component.html',
  styleUrls: ['./noticias.component.css']
})
export class NoticiasComponent implements OnInit {

  noticias:Noticia[];
  p: number = 1;
  btnNew:boolean=false;
btnEdit:boolean=false;
btnDel:boolean=false;
  constructor(private dataService: AuthService,private SpinnerService: NgxSpinnerService,private router:Router,private noticiaService: NoticiaService, private modalService: NgbModal,public activeModal: NgbActiveModal) { }
  ngOnInit(): void {

    const currentUser = this.dataService.currentUserValue;
    if (currentUser) {
      currentUser.user[0].menu.forEach(element => {
        if (element.nombre=='Noticia'){
          this.btnNew=element.new==1; 
          this.btnEdit=element.edit==1; 
          this.btnDel=element.del==1; 
        }
      });
    }

    this.listNoticias();

  }

  listNoticias() {
    this.SpinnerService.show()
    this.noticiaService.listNoticias()
    .subscribe( data => {
      console.log(data.result);
      this.noticias = data.result;
     this.SpinnerService.hide()
      /*302 token expirado*/
       if (data.status==302){
         this.router.navigate(['/login']);
         return;
       }
    },
    error => {
      this.router.navigate(['login']);
      return;
    },
    );
   
  }

 

  onModalRequest():void {
    const modalRef = this.modalService.open(CreateNoticiaComponent, { size: 'lg', backdrop: 'static' } );
    modalRef.componentInstance.id = -1;
    modalRef.componentInstance.accion = 'Nueva';
    modalRef.result.then((result) => {
    console.log(result);

    if (result){
      this.listNoticias();
    }
    
    }).catch( (result) => {
    console.log(result);
    });
    }

    onModalEdit(id: number):void {
      const modalRef = this.modalService.open(CreateNoticiaComponent, { size: 'lg', backdrop: 'static' } );
      modalRef.componentInstance.id = id;
      modalRef.componentInstance.accion = 'Editar';
      modalRef.result.then((result) => {
      console.log(result);
      
      if (result){
        this.listNoticias();
      }
      
      }).catch( (result) => {
      console.log(result);
      });
      }
  
      onModalView(id: number):void {
       const modalRef = this.modalService.open(CreateNoticiaComponent, { size: 'lg', backdrop: 'static' } );
       modalRef.componentInstance.id = id;
       modalRef.componentInstance.accion = 'Ver';
       modalRef.result.then((result) => {
       console.log(result);
       
       }).catch( (result) => {
       console.log(result);
       });
       }
       
       onModalHelp(): void {
        const modalRef = this.modalService.open(HelpComponent, { size: 'md', backdrop: 'static' });
        modalRef.componentInstance.id = ayuda.noticia
          
        modalRef.result.then((result) => {
            
    
        }).catch((result) => {
         
        });
      }
      deleteNoticia(noticia: Noticia) {
        console.log(noticia.id);
        Swal.fire({
          title: 'Advertencia',
          text: "¿Está seguro que desea eliminar la noticia "+noticia.titulo+"?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si!'
        }).then((result) => {
          if (result.value) {
            this.noticiaService.deleteNoticia(noticia.id)
          .subscribe(
            data => this.listNoticias(),
            error => console.log(error));
          }
        })
      
      } 
    }


