import { Socio } from './socio';

export class Organizacion {
  id:number;
  nombre:string;
  estado:number;
  rut:number
  dv:string;
  tipo= new Tipo();
  comuna= new Tipo();
  direccion:string;
  telefono:number
  email:string;
  certificado:Certificado;
}

export class Tipo {
    id: number;
    nombre: string;
}

export class Certificado {
    id: number;
    linea1: string;
    linea2: string;
    linea3: string;
}

export class Autoridad {
  presid: number;
presnombre: string;
presapellidos: string;
tessid: number;
tesnombre: string;
tesapellidos: string;
secsid: string;
secnombre: string;
secapellidos: string;
}

export class Acta {
   id:number;
   fecha:Date;
   numero:number;
   detalle:string;
   acuerdos:string;
   conDoc:boolean;
   mime:string;
   invitados:string;
   activo:number;
   socios:  Socio[];
   usuario:string;
}