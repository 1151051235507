<div class="modal-header">
    <h4 class="modal-title">Resumen Anual</h4>
    <button type="button" class="close" aria-label="Close" (click)="onDismiss('Cross click')">
      <span aria-hidden="true">x</span>
    </button>
  </div>
  <div class="modal-body">
    <button class="btn btn-outline-success text-right btn-sm"  style="float: right;" (click)="generatePdf()"><i style="margin-right: 5px;" class="fa fa-plus"></i>Imprimir</button>
    <table class="table table-bordered table-hover table-sm table-condensed">
        <thead>
          <tr>
    
            <th scope="col" >Socio</th>
            <th scope="col" >Ene</th>
            <th scope="col">Feb</th>
            <th scope="col" >Mar</th>
            <th scope="col" >Abr</th>
            <th scope="col" >May</th>
            <th scope="col">Jun</th>
            <th scope="col">Jul</th>
            <th scope="col" >Ago</th>
            <th scope="col" >Sep</th>
            <th scope="col" >Oct</th>
            <th scope="col">Nov</th>
            <th scope="col">Dic</th>
            <th scope="col">Pagadas</th>
            <th scope="col">Saldo</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let res of resumen">
            <!-- <td>{{socio.id}}</td> -->
            <td >{{res.nombre}}</td>
            <td style="text-align: right;" >{{res.ene | number}}</td>
            <td style="text-align: right;">{{res.feb | number}}</td>
            <td style="text-align: right;">{{res.mar | number}}</td>
            <td style="text-align: right;">{{res.abr | number}}</td>
            <td style="text-align: right;">{{res.may | number}}</td>
            <td style="text-align: right;">{{res.jun | number}}</td>
            <td style="text-align: right;">{{res.jul | number}}</td>
            <td style="text-align: right;">{{res.ago | number}}</td>
            <td style="text-align: right;">{{res.sep | number}}</td>
            <td style="text-align: right;">{{res.oct | number}}</td>
            <td style="text-align: right;">{{res.nov | number}}</td>
            <td style="text-align: right;">{{res.dic | number}}</td>
        
            <td style="text-align: right;">{{res.resumen.pagadas | number}}</td>
            <td style="text-align: right;">{{res.resumen.pendientes | number}}</td>
          </tr>

        </tbody>
        <tfoot>
          <tr>
              <th >Total Pagadas</th>
              <th colspan="14"style="text-align: left;">{{total | number}}</th>
          </tr>
      </tfoot>
      </table>
  </div>