
    <!-- Content Header (Page header) -->
    <section class="content-header">
        <h1>
            Tablero
            <small style="float: right;margin-right: 135px"><button type="button" class="btn text-primary btn-sm d-none d-lg-table-cell accion" 
                (click)="onModalHelp()">Ayuda</button></small>
        </h1>
        <ol class="breadcrumb">
            <li><a href="#"><i class="fa fa-dashboard"></i> Menu</a></li>
            <li class="active">Tablero</li>
        </ol>
    </section>

    <!-- Main content -->
    <section class="content">
               
        <div class="row" *ngFor="let indicador of Indicadores">
              <!-- ./col -->
              <div class="col-lg-3 col-xs-6">
                <!-- small box -->
                <div class="small-box bg-olive">
                    <div class="inner">
                        <h3><sup style="font-size: 20px">$</sup>{{indicador.monto_ingreso | number}}</h3>
                   
                        <p>último ingreso {{indicador.fecha_ingreso}}</p>
                    </div>
                    <div class="icon">
                        <i class="ion ion-arrow-graph-down-right"></i>
                        
                    </div>
                    <!-- <a href="#" class="small-box-footer">More info <i class="fa fa-arrow-circle-right"></i></a> -->
                </div>
            </div>
            <!-- ./col -->
            <div class="col-lg-3 col-xs-6">
                <!-- small box -->
                <div class="small-box bg-aqua">
                    <div class="inner">
                        <h3><sup style="font-size: 20px">$</sup>{{indicador.ingreso_total | number}}</h3>
                        <p>Ingreso anual</p>
                    </div>
                    <div class="icon">
                        <i class="ion ion-stats-bars"><i class="ion ion-arrow-graph-down-right"></i></i>
                    </div>
                    <!-- <a href="#" class="small-box-footer">More info <i class="fa fa-arrow-circle-right"></i></a> -->
                </div>
            </div>
            <!-- ./col -->
            <div class="col-lg-3 col-xs-6">
                <!-- small box -->
                <div class="small-box bg-orange">
                    <div class="inner">
                        <h3><sup style="font-size: 20px"># </sup>{{indicador.total_socios | number}}</h3>
                        <!-- <h3>53<sup style="font-size: 20px">%</sup></h3> -->
                        <p>Socios inscritos</p>
                    </div>
                    <div class="icon">
                        <i class="ion ion-person-add"></i>
                    </div>
                    <!-- <a href="#" class="small-box-footer">More info <i class="fa fa-arrow-circle-right"></i></a> -->
                </div>
            </div>
          
            <div class="col-lg-3 col-xs-6">
                <!-- small box -->
                <div class="small-box bg-red">
                    <div class="inner">
                        <h3><sup style="font-size: 20px"></sup>{{indicador.ultima_acta}}</h3>

                        <p>Fecha última reunión</p>
                    </div>
                    <div class="icon">
                        <i class="ion ion-calendar"></i>
                    </div>
                    <!-- <a href="#" class="small-box-footer">More info <i class="fa fa-arrow-circle-right"></i></a> -->
                </div>
            </div>
            <!-- ./col -->
        </div>
        <!-- /.row -->
        <div class="row">
            <div class="col-lg-12">
                <!-- BAR CHART -->
                <div class="box box-success">
                    <div class="box-header with-border">
                        <h3 class="box-title">Gráfico de ingresos</h3>

                     
                    </div>
                    <div class="box-body">
                        <div class="chart">

                        <canvas baseChart height="80px" [datasets]="barChartData" [labels]="barChartLabels"
                            [options]="barChartOptions" [legend]="barChartLegend" [chartType]="barChartType">
                        </canvas>
                        </div>
                    </div>
                    <!-- /.box-body -->
                </div>
                <!-- /.box -->

            </div>
        </div>

    </section>
    <!-- /.content -->
