import { Component, OnInit, ElementRef, Input, Directive, Renderer2 } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { EgresoService } from '../../../services/egreso.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalConstants } from 'src/app/modules/global-constants';
import { StorageService } from 'src/app/services/storage.service';

enum tipoDocumento {
  ingreso =1 ,
  egreso = 2,
  certificado =3,
  acta=4
}

@Component({
  selector: 'app-view-doc',
  templateUrl: './view-doc.component.html',
  styleUrls: ['./view-doc.component.css']
})
export class ViewDocComponent implements OnInit {
  @Input() id: number;
  @Input() tipo: tipoDocumento;
  @Input() mime: string;
  public fileName;
  public isLoaded = false;
  baseUrl:string = GlobalConstants.apiURL;
  db_name:string;
  constructor(private sanitizer: DomSanitizer,public activeModal: NgbActiveModal,private storageService: StorageService) {
    
   }

  ngOnInit() {
    this.db_name = this.storageService.getCurrentDB();
    if (tipoDocumento.egreso==this.tipo){
      this.fileName = this.sanitizer.bypassSecurityTrustResourceUrl(this.baseUrl+"/getDocEgreso.php?id="+this.id+"&client="+this.db_name)
      ;
    }
    if (tipoDocumento.ingreso==this.tipo){
      this.fileName = this.sanitizer.bypassSecurityTrustResourceUrl(this.baseUrl+"/getDocIngreso.php?id="+this.id+"&client="+this.db_name);
    }
   
    if (tipoDocumento.certificado==this.tipo){
      this.fileName = this.sanitizer.bypassSecurityTrustResourceUrl(this.baseUrl+"/getDoc.php?id="+this.id+"&client="+this.db_name);
    }
    if (tipoDocumento.acta==this.tipo){
      this.fileName = this.sanitizer.bypassSecurityTrustResourceUrl(this.baseUrl+"/getDocActa.php?id="+this.id+"&client="+this.db_name);
    }
  }

  public clearUrl(url) {
     return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  
  onClose(result: boolean): void {
    this.activeModal.close(result);
  }

  onDismiss(reason: String): void {
    this.activeModal.dismiss(reason);
  }
}
