<div class="modal-header">
  <h4 class="modal-title">{{accion}} acta</h4>
  <button type="button" class="close" aria-label="Close" (click)="onDismiss('Cross click')">
    <span aria-hidden="true">x</span>
  </button>
</div>

  <form [formGroup]="formActa" autocomplete="off">
    <div class="modal-body">
    <div class="form-row">

      <div class="col-md-3">
        <label for="inputFecha">Fecha.</label>
        <div class="input-group">
          <input formControlName="fecha" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.fecha.errors }"
            placeholder="yyyy-mm-dd" name="fec" ngbDatepicker #fec="ngbDatepicker">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" *ngIf="!isReadOnly" (click)="fec.toggle()"
              type="button"></button>
          </div>
        </div>
      </div>
      <div class=" col-md-3">
        <label for="inputNumero">Número</label>
        <input type="text" name="numero" formControlName="numero" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.numero.errors }" class="form-control input-sm" placeholder="Número">
        <small class="form-text text-muted" *ngIf="f.numero.hasError('pattern')">Número no válido</small>
      </div>
      <div class=" col-md-6">
        <label for="inputArchivo">Archivo</label>
        <input type="file" name="archivo" (change)="fileProgress($event)" *ngIf="!isReadOnly"
          [ngClass]="{ 'is-invalid': submitted && f.archivo.errors }">
        <small class="form-text text-danger" *ngIf="!verDoc">Debe cargar un archivo</small>
        <small class="form-text text-danger" >{{imageError}}</small>
        <br><code style="color: black;"
          *ngIf="verDoc">Documento-><a (click)="onModalViewDoc()" class="btn btn-default btn-xs" uib-tooltip="Ver"><span class="fa fa-file-pdf-o" aria-hidden="true"></span></a></code>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-4">
        <label for="inputDetalle">Detalle</label>
        <textarea name="detalle" formControlName="detalle" [ngClass]="{ 'is-invalid': submitted && f.detalle.errors }"
          class="form-control input-sm" placeholder="Detalle"></textarea>
        <small class="form-text text-muted" *ngIf="f.detalle.hasError('maxlength')">Excede el límite de caracteres
          permitidos</small>
      </div>

      <div class="form-group col-md-4">
        <label for="inputAcuerdo">Acuerdos</label>
        <textarea name="acuerdos" formControlName="acuerdos"
          [ngClass]="{ 'is-invalid': submitted && f.acuerdos.errors }" class="form-control input-sm"
          placeholder="Acuerdos"></textarea>
        <small class="form-text text-muted" *ngIf="f.acuerdos.hasError('maxlength')">Excede el límite de caracteres
          permitidos</small>
      </div>

 
       <div class="form-group col-md-4">
        <label for="inputInvitados">Invitados</label>
        <textarea name="invitados" formControlName="invitados"
          [ngClass]="{ 'is-invalid': submitted && f.invitados.errors }" class="form-control input-sm"
          placeholder="Invitados"></textarea>
        <small class="form-text text-muted" *ngIf="f.invitados.hasError('maxlength')">Excede el límite de caracteres
          permitidos</small>
      </div> 
    </div>
      <div class="form-row">
      <div class="form-group col-md-6">
        <div class="example-container">
          <label for="inputSocio">Socios</label>
        
          <div
            cdkDropList
            #students2List="cdkDropList"
            [cdkDropListData]="socios"
            [cdkDropListConnectedTo]="[studentsList]"
            class="example-list"
            (cdkDropListDropped)="drop($event)">
            <div class="example-box" *ngFor="let student2 of socios" cdkDrag>{{student2.nombre}}</div>
        </div>
      </div>
       
      </div>
      <div class="form-group col-md-6">
        <div class="example-container">
       
          <label for="inputSocio">Asistentes</label>
          <div
            cdkDropList
            #studentsList="cdkDropList"
            [cdkDropListData]="asistentes"
            [cdkDropListConnectedTo]="[students2List]"
            class="example-list"
            (cdkDropListDropped)="drop($event)">
            <div class="example-box" *ngFor="let student of asistentes" cdkDrag>{{student.nombre}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="onClose(false)">Cerrar</button>
      <button [disabled]="!formActa.valid" id="btnGuardar" type="submit" *ngIf="!isReadOnly" class="btn btn-primary"
        (click)="crearActa(formActa.value)">Guardar</button>

    </div>
  </form>
