import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Proyecto } from 'src/app/modules/proyecto';
import { NotificacionService } from 'src/app/services/notificacion.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ProyectoService } from 'src/app/services/proyecto.service';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-create-proyectos',
  templateUrl: './create-proyectos.component.html',
  styleUrls: ['./create-proyectos.component.css']
})
export class CreateProyectosComponent implements OnInit {
  @Input() public id: number;
  @Input() public accion: string;
  formProyecto: FormGroup;
  submitted = false;
  proyectos: Observable<Proyecto[]>;

  isReadOnly: boolean;
  fileData: File = null;
  constructor(private dataService: AuthService,private notificacion: NotificacionService, private formBuilder: FormBuilder, public activeModal: NgbActiveModal, private router: Router, private elem: ElementRef, private proyectoService: ProyectoService) { }

  ngOnInit() {
    this.formProyecto = this.formBuilder.group({
      nombre: ['', Validators.compose([Validators.required, Validators.maxLength(100)])],
     
    });

    if (this.id != -1) {

      this.listIdProyectos(this.id);
    }
    this.isReadOnly = this.accion == 'Ver';
    if (this.isReadOnly) {
     
      this.formProyecto.get('nombre').disable();


    }
  }

  listIdProyectos(id: number) {
    this.proyectoService.listIdProyecto(id)
      .subscribe(data => {
        this.proyectos = data.result;
        this.formProyecto.patchValue(this.proyectos);

      },
      error => {
        this.router.navigate(['login']);
        return;
      },
      );

  }

  crearProyecto(rProyecto: any) {
    this.submitted = true;


    if (this.formProyecto.invalid) {
      return;
    }
    const proyecto = new Proyecto();
    proyecto.id = this.id;
    proyecto.nombre = rProyecto.nombre;
    proyecto.estado = 1;
    proyecto.orgid = 1;
    let currentUser = this.dataService.currentUserValue;
    proyecto.usuario=currentUser.user[0].username;

    this.proyectoService.createProyecto(proyecto).subscribe(data => {
      if (data.status == 404) {
        this.notificacion.showError(data.message, "Advertencia");
      } else {
        this.notificacion.showSuccess(data.message, "Info")
        this.onClose(true);
      }
    }, (err) => {
      this.notificacion.showError(err.message, "Advertencia");
      //   console.log(err);
    }
    );

  }

  onClose(result: boolean): void {
    this.activeModal.close(result);
  }

  onDismiss(reason: String): void {
    this.activeModal.dismiss(reason);
  }

  get f() { return this.formProyecto.controls; }

}
