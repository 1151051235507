<div 
  gdAreas="header header | side content | footer footer"
  gdGap="16px"
  gdRows="auto auto auto"
  gdAreas.lt-md="header | side | content | footer"
  gdRows.lt-md="auto auto auto auto"
  >

	<div class="header" gdArea="header">
		Header
	</div>

  <div class="side" gdArea="side">
		Side
	</div>

	<div class="content" gdArea="content">
    Content
	</div>

	<div class="footer" gdArea="footer">
		Footer
	</div>
</div>
