 <div class="modal-header">
    <h4 class="modal-title">Ver documento</h4>
    <button type="button" class="close" aria-label="Close" (click)="onDismiss('Cross click')">
        <span aria-hidden="true">x</span>
    </button> 
</div>
<div class="modal-body">
     <object *ngIf="mime=='application/pdf'" width="100%" height="400" [attr.data]="fileName" type="{{mime}}"></object> 
    <img *ngIf="mime!='application/pdf'" [src]="fileName"/>
</div>
