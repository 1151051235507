<div class="login-box">
    <div class="login-logo">
      <a href="http://wwww.miorganizacion.cl"><b>Mi</b>Organización</a>
    </div>
    <!-- /.login-logo -->
    <div class="login-box-body" style="height: 220px;">
      <p class="login-box-msg">Inicia sesión para comenzar</p>
  
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" autocomplete="off">
        <div class="form-group has-feedback">
          <input type="email" formControlName="email" class="form-control" placeholder="Email">
          <span class="glyphicon glyphicon-envelope form-control-feedback"></span>
        </div>
        <div class="form-group has-feedback">
          <input type="password" formControlName="password" class="form-control" placeholder="Password">
          <span class="glyphicon glyphicon-lock form-control-feedback"></span>
        </div>
     
           
          <!-- /.col -->
         
            <button type="submit" [disabled]="!loginForm.valid" class="btn btn-info pull-right">Entrar</button>
         
          <!-- /.col -->
        
      </form>
  
      
  
    </div>
    <!-- /.login-box-body -->
  </div>
  <!-- /.login-box -->

