import { Component, OnInit } from '@angular/core';
import { SocioService } from '../services/socio.service';
import { Socio } from '../modules/socio';
import { CreateSocioComponent } from './create-socio/create-socio.component';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import Swal from 'sweetalert2'
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from '../auth/auth.service';
import { ExcelService } from '../services/excel.service';
import { HelpComponent } from '../components/shared/help/help.component';
import { ayuda } from '../modules/global-constants';


@Component({
  selector: 'app-socios',
  templateUrl: './socios.component.html',
  styleUrls: ['./socios.component.css']
})
export class SociosComponent implements OnInit {

  socios: Socio[];
  p: number = 1;
  btnNew:boolean=false;
  btnEdit:boolean=false;
  btnDel:boolean=false;
  
  constructor(private excelService:ExcelService,private dataService: AuthService,private SpinnerService: NgxSpinnerService, private socioService: SocioService, private modalService: NgbModal, public activeModal: NgbActiveModal, private router: Router) {
  }

  ngOnInit() {
    const currentUser = this.dataService.currentUserValue;
    if (currentUser) {
      currentUser.user[0].menu.forEach(element => {
        if (element.nombre=='Socio'){
          this.btnNew=element.new==1; 
          this.btnEdit=element.edit==1; 
          this.btnDel=element.del==1; 
        }
      });
    }

       this.listSocios();
  }

  listSocios() {

    this.SpinnerService.show();  
    this.socioService.listSocios()
      .subscribe(data => {
        console.log(data.result);
        this.socios = data.result;
        this.SpinnerService.hide();  
        /*302 token expirado*/
        if (data.status == 302) {
          this.router.navigate(['/login']);
          return;
        }
      },
      error => {
        this.router.navigate(['login']);
        return;
      },
      );

  }

  deleteSocio(socio: Socio) {
    console.log(socio.nombre);
    Swal.fire({
      title: 'Advertencia',
      text: "¿Está seguro que desea eliminar el socio " + socio.nombre + "?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si!'
    }).then((result) => {
      if (result.value) {
        this.socioService.deleteSocio(socio.id)
          .subscribe(
            data => this.listSocios(),
            error => console.log(error));
      }
    })

  }

  socioDetails(id: number) {
    this.router.navigate(['detalleSocio', id]);
  }

  onModalRequest(): void {
    const modalRef = this.modalService.open(CreateSocioComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.id = -1;
    modalRef.componentInstance.accion = 'Nuevo';
    modalRef.result.then((result) => {
      console.log(result);

      if (result) {
        this.listSocios();
      }

    }).catch((result) => {
      console.log(result);
    });
  }

  onModalEdit(id: number): void {
    const modalRef = this.modalService.open(CreateSocioComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.accion = 'Editar';
    modalRef.result.then((result) => {
      console.log(result);

      if (result) {
        this.listSocios();
      }

    }).catch((result) => {
      console.log(result);
    });
  }

  onModalView(id: number): void {
    const modalRef = this.modalService.open(CreateSocioComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.accion = 'Ver';
    modalRef.result.then((result) => {
      console.log(result);


    }).catch((result) => {
      console.log(result);
    });
  }

  onModalHelp(): void {
    const modalRef = this.modalService.open(HelpComponent, { size: 'md', backdrop: 'static' });
    modalRef.componentInstance.id = ayuda.socio
      
    modalRef.result.then((result) => {
        

    }).catch((result) => {
     
    });
  }

  exportAsXLSX():void {
var dataTemp=[];
   
this.socios.forEach((Row, index) => {
  var dataRow  = [];
  dataRow['numero']=Row.numero;
  dataRow['nombre']=Row.nombre;
  dataRow['rut']=Row.rut
  dataRow['fecreg']=Row.fecreg
  dataRow['rol']=Row.rol['nombre']	
  dataRow['direccion']=Row.direccion	
  dataRow['fecnac']=Row.fecnac	
  dataRow['email']=Row.email	
  dataRow['telefono']=Row.telefono
  
  dataTemp.push(dataRow)
});   
   

    this.excelService.exportAsExcelFile(dataTemp, 'Socios');
 }

}
