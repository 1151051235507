import { Component, OnInit, Input } from '@angular/core';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Carta } from 'src/app/modules/Carta';
import { DatePipe } from '@angular/common';
import { OrganizacionService } from 'src/app/services/organizacion.service';
import { Router } from '@angular/router';
import { Organizacion } from 'src/app/modules/organizacion';
import { Documento } from 'src/app/modules/documento';
import { Observable } from 'rxjs';
import { SocioService } from 'src/app/services/socio.service';
import { Socio } from 'src/app/modules/socio';
import { NotificacionService } from 'src/app/services/notificacion.service';
import { ViewDocComponent } from 'src/app/components/shared/view-doc/view-doc.component';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DocumentoService } from 'src/app/services/documento.service';
import { AuthService } from 'src/app/auth/auth.service';
import { StorageService } from 'src/app/services/storage.service';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-create-residencia',
  templateUrl: './create-residencia.component.html',
  styleUrls: ['./create-residencia.component.css'],
  providers: [DatePipe]
})

export class CreateResidenciaComponent implements OnInit {
  @Input() public id: number;
  @Input() public accion: string;
  resume = new Carta();
  resumeForm: FormGroup;
  submitted = false;
  organizacion: Organizacion;
  socios: Observable<Socio[]>;
  selectSocios:Socio;
  searchText: string;
  linea1:string='';
  linea2:string='';
  linea3:string='';
  codigo: string;
  ano: string=this.datePipe.transform(new Date(),'yyyy');
  mes: string=this.datePipe.transform(new Date(),'MMMM');
  dia: string=this.datePipe.transform(new Date(),'dd');
  db_name:string;
 constructor(private storageService: StorageService,private dataService: AuthService,public activeModal: NgbActiveModal,private formBuilder: FormBuilder,private modalService:NgbModal,private notificacion: NotificacionService, private socioService: SocioService, private datePipe: DatePipe, private organizacionService:OrganizacionService,private documentoService:DocumentoService, private router:Router) { 
  //  this.resume = JSON.parse(sessionStorage.getItem('resume')) || new Carta();
  }

  generatePdf(action = 'open') {

    this.submitted = true;


    if (this.resumeForm.invalid) {
      return;
    }
   
    const documentDefinition = this.getDocumentDefinition();
    
    const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
    pdfDocGenerator.getBlob((blob) => {
      let so:Socio=  this.resumeForm.get('socio').value;
     

      const documento = new Documento();
      documento.id=-1
      documento.fecha=new Date();
      documento.codigo=this.codigo;
      documento.socio=so.id;
      documento.tipo=1;
      documento.numero=this.resume.id; 
      documento.activo=1;
      documento.nombre=this.resume.nombre;
      let currentUser = this.dataService.currentUserValue;
      documento.usuario=currentUser.user[0].username;

      const formData = new FormData();
      formData.append('archivo', blob);
      formData.append('documento',JSON.stringify(documento))
  
      this.documentoService.createDocumento(formData).subscribe(data => {
        if (data.status=200){
          this.onModalViewDoc(Number(data.message),'application/pdf');
          this.onClose(true);
        }
     //   this.notificacion.showSuccess(data.message, "Info")
 
      }, (err) => {
        this.notificacion.showError(err.message, "Advertencia");
      }
      );
    });

    // switch (action) {
    //   case 'open': pdfMake.createPdf(documentDefinition).open(); break;
    //   case 'print': pdfMake.createPdf(documentDefinition).print(); break;
    //   case 'download': pdfMake.createPdf(documentDefinition).download(); break;
    //   default: pdfMake.createPdf(documentDefinition).open(); break;
    // }
  }
  resetForm() {
    this.resume = new Carta();
  }
  getDocumentDefinition() {
    this.resume.nombre=this.resumeForm.get('nombre').value
    this.resume.direccion=this.resumeForm.get('direccion').value
    this.resume.rut=this.resumeForm.get('rut').value
    this.resume.lugar=this.resumeForm.get('lugar').value
   
    this.resume.id=this.organizacion.certificado.id;

    this.linea1= this.organizacion.certificado.linea1
    .replace('{{nombre}}',this.resume.nombre)
    .replace('{{rut}}',this.resume.rut)
    .replace('{{direccion}}',this.resume.direccion)
    this.linea2= this.organizacion.certificado.linea2
    .replace('{{lugar}}',this.resume.lugar)
    this.linea3=this.organizacion.certificado.linea3
    this.resume.id=this.organizacion.certificado.id;
    
   // sessionStorage.setItem('resume', JSON.stringify(this.resume));    
    return {
      header: {
        margin: [-400, 10, 0, 0],
        columns: [
          [
          this.getProfilePicObject(),
        ],
       [ {
         text: 'Número: '+this.resume.id +'/'+this.ano,
         fontSize: 12,
         alignment: 'right',
         margin: [0, 10, 20, 0]
       }
      ],
     ]
    },
    footer: {
      margin: [20, -100, 0, 0],
      columns: [
        [
          { qr: 'https://go.miorganizacion.cl/verificacion?id='+this.codigo+'.'+this.db_name, fit : 100 },
          {
          text: 'Codigo de Verificación:'+this.codigo+'.'+this.db_name,
          alignment: 'left',
          },
          {
            text: 'Verifique en https://go.miorganizacion.cl/verificacion',
            fontSize: 12,
            alignment: 'left',
            link:'https://go.miorganizacion.cl/verificacion',
            color: 'blue'
            
          }
      ],
    
   ]
  },
      content: [
        {
        text: 'CERTIFICADO DE RESIDENCIA N°' + this.resume.id +'/'+this.ano,
        bold: true,
        fontSize: 20,
        alignment: 'center',
        margin: [0, 80, 0, 20]
      },
      { 
        text:  this.linea1,//'Certifico que el Sr. '+ this.resume.name+ ' Rut: '+this.resume.rut+', actualmente reside en: '+this.resume.address+'.',
        alignment: 'justify',
        margin: [0, 50, 0, 20]
      },
      { 
        text: this.linea2,//'Se extiende el presente certificado para ser presentado en: '+ this.resume.presentado +'.'
        alignment: 'justify',
      },
      { 
        text: this.organizacion.comuna.nombre+', '+ this.dia + ' de '+this.mes+' de '+this.ano,
        margin: [0, 100, 0, 20]
      },{
     
          text: this.organizacion.certificado.linea3,//'Presidente Junta Vecino'+'                           '+'Secretario Junta Vecino',
          margin: [0,100, 0, 40],
         alignment: 'center',
        }
     ]
    }
    
  }
 

  getProfilePicObject() {
   
    if (this.resume.logo) {
      return {
        image: this.resume.logo ,
        width: 75,
        alignment : 'right'
      };
    }
    return null;

  }

  fileChanged(e) {
    const file = e.target.files[0];
    this.getBase64(file);
  }
  getBase64(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      console.log(reader.result);
      this.resume.logo = reader.result as string;
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
    };
  }
   ramdonstring(length) {
    var chars='0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  ngOnInit(): void {
    this.db_name = this.storageService.getCurrentDB();
    this.resumeForm = this.formBuilder.group({
      rut: ['', Validators.required],
      nombre: ['', Validators.required],
      direccion: ['', Validators.required],
      lugar: ['', Validators.required],
      socio: ['', Validators.required],
    });

   this.listSocios() ;
   this.listOrganizacion();
   this.codigo= this.ramdonstring(6);
    
  }

  listSocios() {
    this.socioService.listSocios()
      .subscribe(data => {
        console.log(data.result);
        this.socios = data.result;

        /*302 token expirado*/
        if (data.status == 302) {
          this.router.navigate(['/login']);
          return;
        }
      });

  }

  listOrganizacion() {
    this.organizacionService.listOrganizacion()
    .subscribe( data => {
      console.log(data.result);
       this.organizacion=data.result;  
  
      
      /*302 token expirado*/
       if (data.status==302){
         this.router.navigate(['/login']);
         return;
       }
    });
   
  }
   
  onChange() {
         let so:Socio=  this.resumeForm.get('socio').value;
         if (so.id!=0){
         this.resumeForm.controls.nombre.setValue (so.nombre);
         this.resumeForm.controls.direccion.setValue (so.direccion);
         this.resumeForm.controls.rut.setValue (so.rut+'-'+so.dv);
         }
        

     
  }

  onModalViewDoc(id:number,mime:string):void {
    const modalRef = this.modalService.open(ViewDocComponent, { size: 'lg', backdrop: 'static' } );
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.tipo = 3;
    modalRef.componentInstance.mime = mime;
    modalRef.result.then((result) => {
         
    }).catch( (result) => {
    console.log(result);
    });
    }

    onClose(result: boolean): void {
      this.activeModal.close(result);
    }
  
    onDismiss(reason: String): void {
      this.activeModal.dismiss(reason);
    }
    get f() { return this.resumeForm.controls; }


}

