import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { Tipoitem } from 'src/app/modules/tipoitem';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { TipoitemService } from 'src/app/services/tipoitem.service';
import { NotificacionService } from 'src/app/services/notificacion.service';
import { Tipo } from 'src/app/modules/tipo';
import { TipoService } from 'src/app/services/tipo.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-create-item',
  templateUrl: './create-item.component.html',
  styleUrls: ['./create-item.component.css']
})
export class CreateItemComponent implements OnInit {
  @Input() public id: number;
  @Input() public accion: string;
  formItems: FormGroup;
  submitted = false;
  
  tipos: Observable<Tipo[]>;
  items: Observable<Tipoitem[]>;
  isReadOnly: boolean;
  
  constructor(private dataService: AuthService,private itemService:TipoitemService, private tipoService:TipoService,private notificacion: NotificacionService, private formBuilder: FormBuilder, public activeModal: NgbActiveModal, private router: Router) { }

  ngOnInit() {
    this.formItems = this.formBuilder.group({
      nombre: ['', Validators.compose([Validators.required, Validators.maxLength(100)])],
    
      tipo: ['', Validators.required],
    });
    this.listTipoMovimiento();
    if (this.id != -1) {

      this.listIdItem(this.id);
    }
    this.isReadOnly = this.accion == 'Ver';
    if (this.isReadOnly) {
     
      this.formItems.get('nombre').disable();
      this.formItems.get('tipo').disable();
    }
  }

  listIdItem(id: number) {
    this.itemService.listIdItem(id)
      .subscribe(data => {
        this.items = data.result;
        this.formItems.patchValue(this.items);
        this.formItems.controls.tipo.patchValue(data.result.tipo[0].id);
      });

  }

  listTipoMovimiento() {
    this.tipoService.listTipoMovimiento()
      .subscribe(data => {

        this.tipos = data.result;
        console.log(this.tipos);
        /*302 token expirado*/
        if (data.status == 302) {
          this.router.navigate(['login']);
          return;
        }
      });

  }
  crearItem(rItem: any) {
    this.submitted = true;


    if (this.formItems.invalid) {
      return;
    }
    const item = new Tipoitem();
    item.id = this.id;
    item.nombre = rItem.nombre;
    item.estado = 1;
    item.tipo = rItem.tipo;
    let currentUser = this.dataService.currentUserValue;
    item.usuario=currentUser.user[0].username;

    this.itemService.createItem(item).subscribe(data => {
      if (data.status == 404) {
        this.notificacion.showError(data.message, "Advertencia");
      } else {
        this.notificacion.showSuccess(data.message, "Info")
        this.onClose(true);
      }
    }, (err) => {
      this.notificacion.showError(err.message, "Advertencia");
      
    }
    );

  }

  onClose(result: boolean): void {
    this.activeModal.close(result);
  }

  onDismiss(reason: String): void {
    this.activeModal.dismiss(reason);
  }

  get f() { return this.formItems.controls; }

}
