import { Component, OnInit } from '@angular/core';
import {IngresoService} from '../services/ingreso.service';
import {Ingreso} from '../modules/ingreso';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';

import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateIngresoComponent } from './create-ingreso/create-ingreso.component';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';
import { ViewDocComponent } from '../components/shared/view-doc/view-doc.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../auth/auth.service';
import { ExcelService } from '../services/excel.service';
import { HelpComponent } from '../components/shared/help/help.component';
import { ayuda } from '../modules/global-constants';
declare var $:any;
@Component({
  selector: 'app-ingresos',
  templateUrl: './ingresos.component.html',
  styleUrls: ['./ingresos.component.css']
})
export class IngresosComponent implements OnInit {
  
  p: number = 1;
  ingresos:Ingreso[];
  btnNew:boolean=false;
  btnEdit:boolean=false;
  btnDel:boolean=false;
  constructor(private excelService:ExcelService,private dataService: AuthService,private SpinnerService: NgxSpinnerService,private router:Router,private ingresoService: IngresoService, private modalService: NgbModal,public activeModal: NgbActiveModal) { }

  ngOnInit() {
    const currentUser = this.dataService.currentUserValue;
    if (currentUser) {
      currentUser.user[0].menu.forEach(element => {
        if (element.nombre=='Ingreso'){
          this.btnNew=element.new==1; 
          this.btnEdit=element.edit==1; 
          this.btnDel=element.del==1; 
        }
      });
    }
    $('[data-toggle="popover"]').popover();
   this.listIngresos();
  }
  
  listIngresos() {
    this.SpinnerService.show()
    this.ingresoService.listIngresos()
    .subscribe( data => {
      console.log(data.result);
      this.ingresos = data.result;
     this.SpinnerService.hide()
      /*302 token expirado*/
       if (data.status==302){
         this.router.navigate(['/login']);
         return;
       }
    },
       error => {
         this.router.navigate(['login']);
         return;
       },);
   
  }


  onModalViewDoc(id:number,mime:string):void {
    const modalRef = this.modalService.open(ViewDocComponent, { size: 'lg', backdrop: 'static' } );
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.tipo = 1;
    modalRef.componentInstance.mime = mime;
    modalRef.result.then((result) => {
         
    }).catch( (result) => {
    console.log(result);
    });
    }

  onModalRequest():void {
    const modalRef = this.modalService.open(CreateIngresoComponent, { size: 'lg', backdrop: 'static' } );
    modalRef.componentInstance.id = -1;
    modalRef.componentInstance.accion = 'Nuevo';
    modalRef.result.then((result) => {
    console.log(result);

    if (result){
      this.listIngresos();
    }
    
    }).catch( (result) => {
    console.log(result);
    });
    }

    onModalEdit(id: number):void {
      const modalRef = this.modalService.open(CreateIngresoComponent, { size: 'lg', backdrop: 'static' } );
      modalRef.componentInstance.id = id;
      modalRef.componentInstance.accion = 'Editar';
      modalRef.result.then((result) => {
      console.log(result);
      
      if (result){
        this.listIngresos();
      }
      
      }).catch( (result) => {
      console.log(result);
      });
      }
  

      onModalView(id: number):void {
        const modalRef = this.modalService.open(CreateIngresoComponent, { size: 'lg', backdrop: 'static' } );
        modalRef.componentInstance.id = id;
        modalRef.componentInstance.accion = 'Ver';
        modalRef.result.then((result) => {
        console.log(result);
            
        
        }).catch( (result) => {
        console.log(result);
        });
        }

        onModalHelp(): void {
          const modalRef = this.modalService.open(HelpComponent, { size: 'md', backdrop: 'static' });
          modalRef.componentInstance.id = ayuda.ingreso
            
          modalRef.result.then((result) => {
              
      
          }).catch((result) => {
           
          });
        }

      anularIngreso(ingreso: Ingreso) {
        console.log(ingreso.id);
        Swal.fire({
          title: 'Advertencia',
          text: "¿Está seguro que desea anular el ingreso "+ingreso.id+"?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si!'
        }).then((result) => {
          if (result.value) {
            this.ingresoService.anularIngreso(ingreso.id)
          .subscribe(
            data => this.listIngresos(),
            error => console.log(error));
          }
        })
      
      } 

      exportAsXLSX():void {

        var dataTemp=[];
   
        this.ingresos.forEach((Row, index) => {
          var dataRow  = [];
 
          dataRow['fecha']=Row.fecha;
          dataRow['monto']=Row.monto;
          dataRow['obs']=Row.obs
          dataRow['proyecto']=Row.proyecto[0]['nombre']
          dataRow['item']=Row.items[0]['nombre']
         
          
          dataTemp.push(dataRow)
        });  

        this.excelService.exportAsExcelFile(dataTemp, 'Ingresos');
     }
}
