import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Acta } from 'src/app/modules/organizacion';
import { Observable } from 'rxjs';
import { NotificacionService } from 'src/app/services/notificacion.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActaService } from 'src/app/services/acta.service';
import { Router } from '@angular/router';
import { ViewDocComponent } from 'src/app/components/shared/view-doc/view-doc.component';
import { SocioService } from 'src/app/services/socio.service';
import { Socio } from 'src/app/modules/socio';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { element } from 'protractor';
import { AuthService } from 'src/app/auth/auth.service';
import * as _ from 'lodash';
import { GlobalConstants } from 'src/app/modules/global-constants';

@Component({
  selector: 'app-create-acta',
  templateUrl: './create-acta.component.html',
  styleUrls: ['./create-acta.component.css'],
  providers:  [DatePipe]
})
export class CreateActaComponent implements OnInit {

    
  @Input() public id: number;
  @Input() public accion: string;
  formActa: FormGroup;
  submitted = false;
  actas: Observable<Acta[]>;
  socios: Socio[];
  presocios:Socio[];
  asistentes: Socio[];
  verDoc:boolean=false;
  conDoc: boolean=false;
  isReadOnly: boolean;
  mime:string;
  imageError:string;
  constructor(private dataService: AuthService, private socioService: SocioService ,private notificacion: NotificacionService, private formBuilder: FormBuilder,private modalService: NgbModal, public activeModal: NgbActiveModal, private actaService: ActaService, private router: Router, private datePipe: DatePipe, private elem: ElementRef) { }

  ngOnInit(): void {

    this.formActa = this.formBuilder.group({
      fecha: ['', Validators.required],
      numero: ['', Validators.compose([
        Validators.required,
        Validators.min(1),
        Validators.max(1000000000), 
        Validators.pattern(/\d/)])
      ],
      detalle: ['', Validators.compose([
        Validators.required,
        Validators.maxLength(2000)])
      ],
      acuerdos: ['', Validators.compose([
        Validators.required,
        Validators.maxLength(200)])
      ],
    
      invitados: ['', Validators.compose([
        Validators.required,
        Validators.maxLength(200)])
      ],
      archivo: ['']
    });
   
    this.asistentes= [];

    if (this.id != -1) {

      this.listIdActa(this.id);
    }
    this.isReadOnly = this.accion == 'Ver';
    if (this.isReadOnly) {
    
      this.formActa.get('fecha').disable();
      this.formActa.get('numero').disable();
      this.formActa.get('detalle').disable();
      this.formActa.get('acuerdos').disable();
    
      this.formActa.get('invitados').disable();

    }

    this.listSocios()
     
   
  }

  listSocios() {
    this.socioService.listSociosShort()
      .subscribe(data => {
        console.log(data.result);
        this.presocios = data.result;

        if (this.asistentes){
         
           let ids= new Set(this.asistentes.map(({id})=>id))
           this.socios=this.presocios.filter(({id})=>!ids.has(id))
           
          }
        else {
            this.socios=this.presocios;
         }

        /*302 token expirado*/
        if (data.status == 302) {
          this.router.navigate(['/login']);
          return;
        }
      });

  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
    transferArrayItem(event.previousContainer.data,
                    event.container.data,
                    event.previousIndex,
                    event.currentIndex);
    }
}

  crearActa(rEgreso: any) {
    this.submitted = true;
    if (this.formActa.invalid) {
      return;
    }
    if (!this.conDoc &&  this.id==-1){
      this.notificacion.showError("Debe ingresar un documento", "Advertencia");
      return;
    }
    const egreso = new Acta();
    egreso.id = this.id;
    egreso.numero = rEgreso.numero;
    egreso.detalle = rEgreso.detalle;
    egreso.invitados = rEgreso.invitados;
    
    egreso.activo = 1;
    egreso.acuerdos = rEgreso.acuerdos;
    egreso.conDoc=this.conDoc;
    egreso.socios= this.asistentes;

    egreso.fecha = new Date(rEgreso.fecha.year, rEgreso.fecha.month - 1, rEgreso.fecha.day);
    let currentUser = this.dataService.currentUserValue;
    egreso.usuario=currentUser.user[0].username;

    const formData = new FormData();
    formData.append('archivo', this.formActa.get('archivo').value);
    formData.append('acta',JSON.stringify(egreso))

    this.actaService.createActa(formData).subscribe(data => {
      this.notificacion.showSuccess(data.message, "Info")
      this.onClose(true);

    }, (err) => {
      this.notificacion.showError(err.message, "Advertencia");
    }
    );

  }

  fileProgress(event: any) {
    if (event.target.files.length > 0) {
      if (event.target.files[0].size > GlobalConstants.max_size) {
        this.imageError =
            'El tamaño máximo permitido es ' + GlobalConstants.max_size / 1048576 + 'Mb';
        return false;
    }
    if (!_.includes(GlobalConstants.allowed_types, event.target.files[0].type)) {
        this.imageError = 'Solo se permiten archivos( JPG | PNG | PDF )';
        return false;
    }
      const file = event.target.files[0];
      this.formActa.get('archivo').setValue(file);
      this.conDoc=true;
    }
   
  }

  listIdActa(id: number) {
    this.actaService.listIdActa(id)
      .subscribe(data => {
        this.actas = data.result;
        this.asistentes=data.result.asistentes;
        if  (this.asistentes== null){
            this.asistentes=[];
        }
        this.formActa.patchValue(this.actas);
        const aaaa = Number(this.datePipe.transform(data.result.fecha, 'yyyy'));
        const mm = Number(this.datePipe.transform(data.result.fecha, 'MM'));
        const dd = Number(this.datePipe.transform(data.result.fecha, 'dd'));
        this.formActa.controls.fecha.setValue({ year: aaaa, month: mm, day: dd });
      
        this.verDoc= data.result.doc>0;
        this.mime=data.result.mime;
      });
  }

  onModalViewDoc():void {
    const modalRef = this.modalService.open(ViewDocComponent, { size: 'lg' } );
    modalRef.componentInstance.id = this.id;
    modalRef.componentInstance.tipo = 4;
    modalRef.componentInstance.mime = this.mime;
    modalRef.result.then((result) => {
         
    }).catch( (result) => {
    console.log(result);
    });
    }

  onClose(result: boolean): void {
    this.activeModal.close(result);
  }

  onDismiss(reason: String): void {
    this.activeModal.dismiss(reason);
  }

  get f() { return this.formActa.controls; }

}
