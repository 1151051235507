import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import {FormsModule,ReactiveFormsModule } from '@angular/forms'; 
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SociosComponent } from './socios/socios.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoginComponent } from './login/login.component';
import {NgbModule, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NotFoundComponent } from './not-found/not-found.component';
import { Router } from '@angular/router';
import { CreateSocioComponent } from './socios/create-socio/create-socio.component';
import localEsCL from '@angular/common/locales/es-CL';
import { registerLocaleData } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { TokenInterceptor } from './components/shared/tokeninterceptor/tokeninterceptor.component';
import { IngresosComponent } from './ingresos/ingresos.component';
import { CreateIngresoComponent } from './ingresos/create-ingreso/create-ingreso.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { HomeComponent } from './components/shared/home/home.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { AuthService } from './auth/auth.service';
import { AuthguardGuard } from './auth/authguard.guard';
import { StorageService } from './services/storage.service';
import { HomeLayoutComponent } from './layouts/home-layout.component';
import { LoginLayoutComponent } from './layouts/login-layout.component';
import { EgresosComponent } from './egresos/egresos.component';
import { CreateEgresoComponent } from './egresos/create-egreso/create-egreso.component';
import { ProyectosComponent } from './proyectos/proyectos.component';
import { CreateProyectosComponent } from './proyectos/create-proyectos/create-proyectos.component';
import { ItemsComponent } from './items/items.component';
import { CreateItemComponent } from './items/create-item/create-item.component';

import { FooternavbarComponent } from './components/shared/footer/footernavbar.component';
import { ViewDocComponent } from './components/shared/view-doc/view-doc.component';
import { CuotasComponent } from './cuotas/cuotas.component';
import { MenuComponent } from './components/shared/menu/menu.component';
import { CardComponent } from './components/shared/card/card.component';
import { ChartsModule } from 'ng2-charts';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FilterPipe } from './components/shared/filter.pipe';

import { CreateResidenciaComponent } from './residencia/create-residencia/create-residencia.component';
import { ResidenciasComponent } from './residencia/residencias/residencias.component';

import {NgxPaginationModule} from 'ngx-pagination';
import { AboutComponent } from './about/about.component';
import { ActasComponent } from './actas/actas.component';
import { CreateActaComponent } from './actas/create-acta/create-acta.component';
import { Ng2Rut, RutValidator } from 'ng2-rut';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NoticiasComponent } from './noticias/noticias.component';
import { CreateNoticiaComponent } from './noticias/create-noticia/create-noticia.component';
import { ResumenCuotasComponent } from './cuotas/resumen-cuotas/resumen-cuotas.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { NumberFormatPipe } from './components/shared/number-format.pipe';
import { HelpComponent } from './components/shared/help/help.component';  
registerLocaleData(localEsCL,'es-CL');

@NgModule({
  declarations: [
    AppComponent,
    SociosComponent,
    LoginComponent,
    NotFoundComponent,
    CreateSocioComponent,
    IngresosComponent,
    CreateIngresoComponent,
    HomeComponent,
    HeaderComponent,
    HomeLayoutComponent,
    LoginLayoutComponent,
    EgresosComponent,
    CreateEgresoComponent,
    ProyectosComponent,
    CreateProyectosComponent,
    ItemsComponent,
    CreateItemComponent,
    FooternavbarComponent,
    ViewDocComponent,
    CuotasComponent,
    MenuComponent,
    CardComponent,
    FilterPipe,
    CreateResidenciaComponent,
    ResidenciasComponent,
    AboutComponent,
    ActasComponent,
    CreateActaComponent,
    NoticiasComponent,
    CreateNoticiaComponent,
    ResumenCuotasComponent,
    NumberFormatPipe,
    HelpComponent,
   
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
     ReactiveFormsModule,
     NgbModule,
     FontAwesomeModule,
     BrowserAnimationsModule,
     ToastrModule.forRoot(),
     NgSelectModule,
     ChartsModule,
     FlexLayoutModule,
     NgxPaginationModule,
     Ng2Rut,
     DragDropModule,
     NgxSpinnerModule  
         
  ],
 entryComponents: [
   CreateSocioComponent,
   CreateIngresoComponent,
   CreateEgresoComponent,
   CreateProyectosComponent,
   CreateItemComponent,
   ViewDocComponent 
  ],
  providers: [RutValidator, AuthService, AuthguardGuard,StorageService, NgbActiveModal,{ provide: LOCALE_ID, useValue: 'es-CL' }, {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi : true}],
  bootstrap: [AppComponent]
})
export class AppModule {
 // public router: Router
 }
