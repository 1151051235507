import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { Egreso } from 'src/app/modules/egreso';
import { Proyecto } from 'src/app/modules/proyecto';
import { Tipoitem } from 'src/app/modules/tipoitem';
import { NotificacionService } from 'src/app/services/notificacion.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EgresoService } from 'src/app/services/egreso.service';
import { Router } from '@angular/router';
import { ProyectoService } from 'src/app/services/proyecto.service';
import { TipoitemService } from 'src/app/services/tipoitem.service';
import { ViewDocComponent } from 'src/app/components/shared/view-doc/view-doc.component';
import { AuthService } from 'src/app/auth/auth.service';
import * as _ from 'lodash';
import { GlobalConstants } from 'src/app/modules/global-constants';

@Component({
  selector: 'app-create-egreso',
  templateUrl: './create-egreso.component.html',
  styleUrls: ['./create-egreso.component.css'],
  providers: [DatePipe]
})
export class CreateEgresoComponent implements OnInit {

  @Input() public id: number;
  @Input() public accion: string;
  formEgreso: FormGroup;
  submitted = false;
  egresos: Observable<Egreso[]>;
  proyectos: Observable<Proyecto[]>;
  items: Observable<Tipoitem[]>;
  conDoc: boolean=false;
  verDoc: boolean=false;
  isReadOnly: boolean;
  mime:string;
  imageError:string;
  constructor(private dataService: AuthService,private notificacion: NotificacionService, private formBuilder: FormBuilder,private modalService: NgbModal, public activeModal: NgbActiveModal, private egresoService: EgresoService, private router: Router, private datePipe: DatePipe, private elem: ElementRef, private proyectoService: ProyectoService, private tipoitemService: TipoitemService) { }

  ngOnInit() {
    this.formEgreso = this.formBuilder.group({
      fecha: ['', Validators.required],
      monto: ['', Validators.compose([
        Validators.required,
        Validators.min(1),
        Validators.max(1000000000), 
        Validators.pattern(/\d/)])
      ],
      item: ['', Validators.required],
      proyecto: ['', Validators.required],
    
      obs: ['', Validators.compose([
        Validators.required,
        Validators.maxLength(1000)])
      ],
      archivo: ['']
    });
    this.listProyectos();
    this.listItems();
  
    
    
    if (this.id != -1) {

      this.listIdEgreso(this.id);
    }
    this.isReadOnly = this.accion == 'Ver';
    if (this.isReadOnly) {
   
      this.formEgreso.get('fecha').disable();
      this.formEgreso.get('monto').disable();
      this.formEgreso.get('item').disable();
      this.formEgreso.get('proyecto').disable();
    
      this.formEgreso.get('obs').disable();

    }
  }

  listProyectos() {
    this.proyectoService.listProyectos()
      .subscribe(data => {

        this.proyectos = data.result;
        console.log(this.proyectos);
        /*302 token expirado*/
        if (data.status == 302) {
          this.router.navigate(['login']);
          return;
        }
      });
  }

  listItems() {
    this.tipoitemService.listTipoItems(2)
      .subscribe(data => {
        this.items = data.result;
      });
  }

  crearEgreso(rEgreso: any) {
    this.submitted = true;
    if (this.formEgreso.invalid) {
      return;
    }
    if (!this.conDoc &&  this.id==-1){
      this.notificacion.showError("Debe ingresar un documento", "Advertencia");
      return;
    }
    const egreso = new Egreso();
    egreso.id = this.id;
    egreso.monto = rEgreso.monto;
    egreso.proyecto = rEgreso.proyecto;
    egreso.items = rEgreso.item;
    //ingreso.doc =rIngreso.item;
    egreso.estado = 1;
    egreso.obs = rEgreso.obs;
    egreso.conDoc=this.conDoc;

    egreso.fecha = new Date(rEgreso.fecha.year, rEgreso.fecha.month - 1, rEgreso.fecha.day);
    let currentUser = this.dataService.currentUserValue;
    egreso.usuario=currentUser.user[0].username;

    const formData = new FormData();
    formData.append('archivo', this.formEgreso.get('archivo').value);
    formData.append('egreso',JSON.stringify(egreso))

    this.egresoService.createEgreso(formData).subscribe(data => {
      this.notificacion.showSuccess(data.message, "Info")
      this.onClose(true);

    }, (err) => {
      this.notificacion.showError(err.message, "Advertencia");
    }
    );

  }

  fileProgress(event: any) {
    if (event.target.files.length > 0) {
        if (event.target.files[0].size > GlobalConstants.max_size) {
              this.imageError =
                  'El tamaño máximo permitido es ' + GlobalConstants.max_size / 1048576 + 'Mb';
              return false;
          }
          if (!_.includes(GlobalConstants.allowed_types, event.target.files[0].type)) {
              this.imageError = 'Solo se permiten archivos( JPG | PNG | PDF )';
              return false;
          }
      const file = event.target.files[0];
      this.formEgreso.get('archivo').setValue(file);
      this.conDoc=true;
    }
   
  }

  listIdEgreso(id: number) {
    this.egresoService.listIdEgreso(id)
      .subscribe(data => {
        this.egresos = data.result;
        this.formEgreso.patchValue(this.egresos);
        const aaaa = Number(this.datePipe.transform(data.result.fecha, 'yyyy'));
        const mm = Number(this.datePipe.transform(data.result.fecha, 'MM'));
        const dd = Number(this.datePipe.transform(data.result.fecha, 'dd'));
        this.formEgreso.controls.fecha.setValue({ year: aaaa, month: mm, day: dd });
        this.formEgreso.controls.proyecto.patchValue(data.result.proyecto[0].id);
        this.formEgreso.controls.item.patchValue(data.result.items[0].id);
        this.verDoc= data.result.doc>0;
        this.mime=data.result.mime;
      });
  }

  onModalViewDoc():void {
    const modalRef = this.modalService.open(ViewDocComponent, { size: 'md' } );
    modalRef.componentInstance.id = this.id;
    modalRef.componentInstance.tipo = 2;
    modalRef.componentInstance.mime = this.mime;
    modalRef.result.then((result) => {
         
    }).catch( (result) => {
    console.log(result);
    });
    }

  onClose(result: boolean): void {
    this.activeModal.close(result);
  }

  onDismiss(reason: String): void {
    this.activeModal.dismiss(reason);
  }

  get f() { return this.formEgreso.controls; }
}
