import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { Noticia } from 'src/app/modules/noticia';
import { NotificacionService } from 'src/app/services/notificacion.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { NoticiaService } from 'src/app/services/noticia.service';
import { AuthService } from 'src/app/auth/auth.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-create-noticia',
  templateUrl: './create-noticia.component.html',
  styleUrls: ['./create-noticia.component.css'],
  providers: [DatePipe]
})
export class CreateNoticiaComponent implements OnInit {
  @Input() public id: number;
  @Input() public accion: string;
  formNoticia: FormGroup;
  submitted = false;
  noticia: Observable<Noticia[]>;

  isReadOnly: boolean;
  
  constructor(private datePipe: DatePipe,private dataService: AuthService,private notificacion: NotificacionService, private formBuilder: FormBuilder, public activeModal: NgbActiveModal, private router: Router, private elem: ElementRef, private noticiaService: NoticiaService) { }

  ngOnInit() {
    this.formNoticia = this.formBuilder.group({
      titulo: ['', Validators.compose([Validators.required, Validators.maxLength(1000)])],
      cuerpo: ['', Validators.compose([Validators.required, Validators.maxLength(2000)])],
      desde: ['', Validators.required],
      hasta: ['', Validators.required],
    });

    if (this.id != -1) {

      this.listIdNoticia(this.id);
    }
    this.isReadOnly = this.accion == 'Ver';
    if (this.isReadOnly) {
     
      this.formNoticia.get('titulo').disable();
      this.formNoticia.get('cuerpo').disable();
      this.formNoticia.get('desde').disable();
      this.formNoticia.get('hasta').disable();
    }
  }

  listIdNoticia(id: number) {
    this.noticiaService.listIdNoticia(id)
      .subscribe(data => {
        this.noticia = data.result;
        this.formNoticia.patchValue(this.noticia);
        const da = Number(this.datePipe.transform(data.result.desde, 'yyyy'));
        const dm = Number(this.datePipe.transform(data.result.desde, 'MM'));
        const dd = Number(this.datePipe.transform(data.result.desde, 'dd'));
        this.formNoticia.controls.desde.setValue({ year: da, month: dm, day: dd });

        const ha = Number(this.datePipe.transform(data.result.hasta, 'yyyy'));
        const hm = Number(this.datePipe.transform(data.result.hasta, 'MM'));
        const hd = Number(this.datePipe.transform(data.result.hasta, 'dd'));
        this.formNoticia.controls.hasta.setValue({ year: ha, month: hm, day: hd });
      });

  }

  crearNoticia(rProyecto: any) {
    this.submitted = true;


    if (this.formNoticia.invalid) {
      return;
    }
    const currentUser = this.dataService.currentUserValue;
    const proyecto = new Noticia();
    proyecto.id = this.id;
    proyecto.titulo = rProyecto.titulo;
    proyecto.activo = 1;
    proyecto.cuerpo = rProyecto.cuerpo;
    proyecto.desde = new Date(rProyecto.desde.year, rProyecto.desde.month - 1, rProyecto.desde.day);
    proyecto.hasta = new Date(rProyecto.hasta.year, rProyecto.hasta.month - 1, rProyecto.hasta.day);
    proyecto.socio.id= currentUser.user[0].id;
   
    proyecto.usuario=currentUser.user[0].username;
    this.noticiaService.createNoticia(proyecto).subscribe(data => {
      if (data.status == 404) {
        this.notificacion.showError(data.message, "Advertencia");
      } else {
        this.notificacion.showSuccess(data.message, "Info")
        this.onClose(true);
      }
    }, (err) => {
      this.notificacion.showError(err.message, "Advertencia");
      //   console.log(err);
    }
    );

  }

  onClose(result: boolean): void {
    this.activeModal.close(result);
  }

  onDismiss(reason: String): void {
    this.activeModal.dismiss(reason);
  }

  get f() { return this.formNoticia.controls; }

}
