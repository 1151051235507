import { Injectable } from '@angular/core';
import { ApiResponse } from '../modules/apiresponse';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { GlobalConstants } from '../modules/global-constants';

@Injectable({
  providedIn: 'root'
})
export class TipoitemService {
  baseUrl:string = GlobalConstants.apiURL;
  currentUser = this.dataService.currentUserValue;
  constructor(private httpClient: HttpClient, private dataService: AuthService ) { }

  public listTipoItems(tipo:number) : Observable<ApiResponse>{
    return this.httpClient.get<ApiResponse>(this.baseUrl +'/listTipoItem.php?tipo='+tipo);
  }

  public listItems() : Observable<ApiResponse>{
    return this.httpClient.get<ApiResponse>(this.baseUrl +'/listItem.php');
  }

  public listIdItem(Id: number) : Observable<ApiResponse>{
    return this.httpClient.get<ApiResponse>(this.baseUrl +'/listIdItem.php?id='+Id);
  }
  public createItem (formData: any): Observable<ApiResponse> {     
      return this.httpClient.post<ApiResponse>(this.baseUrl +'/createItem.php', formData)
  }

  public deleteItem(id: number): Observable<ApiResponse> {
    return this.httpClient.get<ApiResponse>(this.baseUrl +'/deleteItem.php?id='+id+'&usuario='+this.currentUser.user[0].username);
  }
}
