<div class="modal-header">
  <h4 class="modal-title">{{accion}} socio</h4>
  <button type="button" class="close" aria-label="Close" (click)="onDismiss('Cross click')">
    <span aria-hidden="true">x</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="registerSocio" autocomplete="off">
    <div class="row">
      <div class="col-md-3">
        <label for="inputRut">Rut</label>
        <div class="input-group">
          <span class="input-group-addon">
            <input  type="checkbox" formControlName="select"  (change)="changeStatus( $event.target.checked)">
          </span>
          <input type="text" name="rut"  formControlName="rut" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.rut.errors }" placeholder="99999999-9">
       </div>
       <small  [hidden]="registerSocio.controls.rut.valid || registerSocio.controls.rut.pristine" class="form-text text-muted" *ngIf="registerSocio.controls.rut.errors && registerSocio.controls.rut.errors.invalidRut">Rut incorrecto</small>
  
      </div>
       <div class="form-group col-md-2">
        <label for="">Num. socio</label>
        <input type="text" name="numero"  formControlName="numero" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.numero.errors }" autocomplete="off" class="form-control input-sm"
          placeholder="numero">
        <div *ngIf="submitted && f.numero.errors" class="invalid-feedback">
        </div>
        <small class="form-text text-muted" *ngIf="f.numero.hasError('pattern')">Número no válido</small>
      </div>
      <div class="form-group col-md-3">
        <label for="inputNombre">Fec. Reg.</label>
        <div class="input-group">
          <!-- <ngbd-datepicker-popup [parentForm]="registerSocio" [fControlName]="'fecreg'" [ngClass]="{ 'is-invalid': submitted && f.fecreg.errors }"  ></ngbd-datepicker-popup>  -->
          <input formControlName="fecreg" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.fecreg.errors }" placeholder="yyyy-mm-dd" name="fr" ngbDatepicker
            #fr="ngbDatepicker">
          <div class="input-group-append">
            <button class="btn btn-secondary2 calendar" *ngIf="!isReadOnly" (click)="fr.toggle()"
              type="button"></button>
          </div>
        </div>
      </div>
      <div class="form-group col-md-3">
        <label for="inputNombre">Fec. nac.</label>
        <div class="input-group">
          <!-- <ngbd-datepicker-popup [parentForm]="registerSocio" [fControlName]="'fecnac'" [ngClass]="{ 'is-invalid': submitted && f.fecnac.errors }" ></ngbd-datepicker-popup>  -->
          <input formControlName="fecnac"  class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.fecnac.errors }" placeholder="yyyy-mm-dd" name="fn" ngbDatepicker
            #fn="ngbDatepicker">
          <div class="input-group-append">
            <button class="btn btn-secondary2 calendar" *ngIf="!isReadOnly" (click)="fn.toggle()"
              type="button"></button>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-4">
        <label for="inputNombre">Nombre</label>
        <input type="text" name="nombre"  formControlName="nombre" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }" class="form-control input-sm" placeholder="Nombre">
          <small class="form-text text-muted" *ngIf="f.nombre.hasError('minlength') ||f.nombre.hasError('maxlength')">Excede el límite de caracteres permitidos</small>
      </div>
      <div class="form-group col-md-5">
        <label for="inputApellidos">Apellidos</label>
        <input type="text" name="apellidos"  formControlName="apellidos" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.apellidos.errors }" class="form-control input-sm"
          placeholder="Apellidos">
          <small class="form-text text-muted" *ngIf="f.apellidos.hasError('minlength') ||f.apellidos.hasError('maxlength')">Excede el límite de caracteres permitidos</small>
      </div>
      <div class="form-group col-md-3">
        <label for="inputproyecto">Rol</label>
        <select class="custom-select" formControlName="rol" [ngClass]="{ 'is-invalid': submitted && f.rol.errors }">
          <option *ngFor="let rol of rolSocio ; let i = index" [value]="rolSocio[i].id">
            {{rolSocio[i].nombre}}
          </option>
        </select>
      </div>
    </div>
 
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="inputDireccion">Dirección</label>
        <input type="text" name="direccion"  formControlName="direccion" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.direccion.errors }" class="form-control input-sm"
          placeholder="Dirección">
          <small class="form-text text-muted" *ngIf="f.direccion.hasError('minlength') ||f.direccion.hasError('maxlength')">Excede el límite de caracteres permitidos</small>
      </div>
      <div class="form-group col-md-6">
        <label for="inputCiudad">Ciudad</label>
        <input type="text" name="ciudad"  formControlName="ciudad" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.ciudad.errors }" autocomplete="off" class="form-control input-sm"
          placeholder="Ciudad">
          <small class="form-text text-muted" *ngIf="f.ciudad.hasError('minlength') ||f.ciudad.hasError('maxlength')">Excede el límite de caracteres permitidos</small>
      </div>
    </div>
    <div class="form-row">
   
    
      <div class="form-group col-md-4">
        <label for="inputApellidos">Profesión</label>
        <input type="text" name="profesion"  formControlName="profesion" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.profesion.errors }" class="form-control input-sm"
          placeholder="Profesión">
          <small class="form-text text-muted" *ngIf="f.profesion.hasError('minlength') ||f.profesion.hasError('maxlength')">Excede el límite de caracteres permitidos</small> 
      </div>
    
      <div class="form-group col-md-4">
        <label for="inputEmail4">Email</label>
        <input type="text" name="email" formControlName="email" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.email.errors }" class="form-control input-sm" 
          placeholder="Correo electrónico">
        <small class="form-text text-muted" *ngIf="f.email.hasError('email')">Debe ser email válido</small>
        <small class="form-text text-muted" *ngIf="f.email.hasError('minlength') ||f.email.hasError('maxlength')">Excede el límite de caracteres permitidos</small> 
      </div>
      <div class="form-group col-md-4">
        <label for="inputRut">Teléfono</label>
        <input type="text" name="telefono" formControlName="telefono" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.telefono.errors }" autocomplete="off" class="form-control input-sm"
          placeholder="Telefono">
        <small class="form-text text-muted" *ngIf="f.telefono.hasError('minlength') ||f.telefono.hasError('maxlength')||f.telefono.hasError('pattern')">Telefono no válidos</small>
      </div>
    
    </div>
    <div class="form-row">
      <div class="form-group col-md-12">
        <label for="inputObs">Observación</label>
        <textarea name="obs" formControlName="obs" rows="1"  [ngClass]="{ 'is-invalid': submitted && f.obs.errors }"
          class="form-control input-sm" placeholder="Obs"></textarea>
          <small class="form-text text-muted"  *ngIf="f.obs.hasError('maxlength')">Observación no válidos</small>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="onClose(false)">Cerrar</button>
      <button id="btnGuardar" *ngIf="!isReadOnly" type="submit" class="btn btn-primary"
        (click)="crearSocio(registerSocio.value)">Guardar</button>

    </div>

    </form>
</div>