export class GlobalConstants {
  
 public static apiURL: string = "https://go.miorganizacion.cl/api";
 //public static apiURL: string = "http://localhost/api"
 public static  max_size = 1048576;
 public static  allowed_types = ['image/png', 'image/jpeg','application/pdf'];

}


export enum ayuda {
    tablero=1,
    socio=2,
    acta=3,
    residencia=4,
    cuota=5,
    ingreso=6,
    egreso=7,
    proyecto=8,
    item=9,
    noticia=10,
    organizacion=11
}