import { Component, OnInit } from '@angular/core';
import { CuotaService } from 'src/app/services/cuota.service';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { DecimalPipe } from '@angular/common';
import { DatePipe } from '@angular/common';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-resumen-cuotas',
  templateUrl: './resumen-cuotas.component.html',
  styleUrls: ['./resumen-cuotas.component.css'],
  providers: [DatePipe,DecimalPipe]
})
export class ResumenCuotasComponent implements OnInit {
  resumen:any=[];
  p: number = 1;
  total:number=0;
  resume: any=Array();
  userNum:number;
  constructor(private decimalPipe: DecimalPipe, private datePipe: DatePipe, private cuotaService:CuotaService,private router:Router,public activeModal: NgbActiveModal,) { }

  ngOnInit(): void {
  
    this.ResumenAnual();
  }

  ResumenAnual() {
    this.cuotaService.ResumenAnual()
      .subscribe(data => {

        this.resumen = data.result;
        this.resumen.forEach(element => {
            this.total += parseInt (element.resumen.montopagada);
    });
        //this.selectMeses.id = this.Meses[0];
        console.log(this.resumen);
        /*302 token expirado*/
        if (data.status == 302) {
          this.router.navigate(['login']);
          return;
        }
      });
  }

  onClose(result: boolean): void {
    this.activeModal.close(result);
  }

  onDismiss(reason: String): void {
    this.activeModal.dismiss(reason);
  }
  generatePdf() {
  

      
    const documentDefinition = this.getDocumentDefinition();
    pdfMake.createPdf(documentDefinition).open(); 
  }

  getDocumentDefinition() {
     
   
    return {
      pageSize: 'LETTER',
      pageOrientation: 'landscape',
      pageMargins: [ 40, 30, 40, 60 ],
      header: {

         text: 'Fecha: '+this.datePipe.transform(new Date(), 'dd MMM yyyy HH:mm'),
         fontSize: 12,
         alignment: 'right',
         margin: [0, 10, 20, 0]
  
    },
    footer: function(currentPage, pageCount) { 
        return [
          {
            alignment: 'center',
            text:'Página '+currentPage.toString() + ' de ' + pageCount
          }
        ] 
      },
      content: [
        {
        text: 'RESUMEN CUOTAS PERIODO '+this.datePipe.transform(new Date(), 'yyyy'),
        bold: true,
        fontSize: 20,
        alignment: 'center',
        margin: [0, 20, 0, 20]
      },
      this.getTabla(),
      { 
        bold: true,
        fontSize: 15,
        text:  'Total Pagadas: '+ this.decimalPipe.transform(this.total),
        alignment: 'left'
  
      },
      
     ]
    }
    
  }

  getTabla() {
    var __this=this;
    var bodyData = [];
    var sourceData = this.resumen
   
    bodyData.push(['Socio','Ene','Feb','Mar','Abr','May','Jun','Jul','Ago','Sep','Oct','Nov','Dic','Pagados','Pendientes'])
   
    sourceData.forEach(function(sourceRow) {
      var dataRow = [];
    
      dataRow.push(sourceRow.nombre);
      dataRow.push({alignment: 'right',text: __this.decimalPipe.transform(sourceRow.ene)});
      dataRow.push({alignment: 'right',text: __this.decimalPipe.transform(sourceRow.feb)});
      dataRow.push({alignment: 'right',text: __this.decimalPipe.transform(sourceRow.mar)});
      dataRow.push({alignment: 'right',text: __this.decimalPipe.transform(sourceRow.abr)});
      dataRow.push({alignment: 'right',text: __this.decimalPipe.transform(sourceRow.may)});
      dataRow.push({alignment: 'right',text: __this.decimalPipe.transform(sourceRow.jun)});
      dataRow.push({alignment: 'right',text: __this.decimalPipe.transform(sourceRow.jul)});
      dataRow.push({alignment: 'right',text: __this.decimalPipe.transform(sourceRow.ago)});
      dataRow.push({alignment: 'right',text: __this.decimalPipe.transform(sourceRow.sep)});
      dataRow.push({alignment: 'right',text: __this.decimalPipe.transform(sourceRow.oct)});
      dataRow.push({alignment: 'right',text: __this.decimalPipe.transform(sourceRow.nov)});
      dataRow.push({alignment: 'right',text: __this.decimalPipe.transform(sourceRow.dic)});
      dataRow.push({alignment: 'right',text: __this.decimalPipe.transform(sourceRow.resumen.pagadas)});
      dataRow.push({alignment: 'right',text: __this.decimalPipe.transform(sourceRow.resumen.pendientes)});
     
      bodyData.push(dataRow)
    });

    return {
      table: {
        widths: ['auto', 'auto', 'auto', 'auto','auto','auto', 'auto', 'auto', 'auto','auto','auto', 'auto', 'auto', 'auto','auto'],
        headerRows: 1,
        body:
          bodyData
       }
    };
  }



 


}
