import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { StorageService } from '../services/storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  invalidLogin: boolean = false;
  constructor(private fb: FormBuilder,private dataService: AuthService,private router:Router,private storageService:StorageService ) {
   
   }
  ngOnDestroy(): void {
    document.body.className='';
  }

  ngOnInit() {
      this.storageService.removeCurrentSession();
    
    document.body.className="hold-transition login-page";
    this.loginForm = this.fb.group({
 
      email: ['', [Validators.required,Validators.minLength(1), Validators.email]],
      password: ['', Validators.required]
 
    });

   //this.loginForm.controls.email.setValue("admin@cfeq.cl");
   //this.loginForm.controls.password.setValue("1234");
   
  }
  onSubmit()
  {
    if (this.loginForm.invalid) {
      return;
    }
    const loginPayload = {
      username: this.loginForm.controls.email.value,
      password: this.loginForm.controls.password.value
    }

    this.dataService.login(loginPayload)
    

       .pipe(first())
       .subscribe(
           data => {

            if (data.status == 400) {
              alert("Usuario o clave incorrecta ")
              return;
            }
            this.storageService.setCurrentSession(data.result);
          
            this.router.navigate(['/']);
           
           },
           error => {
               alert("Usuario o clave incorrecta "+ error.message)
           });
  }
  get email() { return this.loginForm.get('email'); }
  get password() { return this.loginForm.get('password'); }
}
