import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { StorageService } from '../../../services/storage.service';
import { retry, catchError } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor  {
  constructor(private injector: Injector,private storageService: StorageService) { }
  
   intercept( request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
     let token = this.storageService.getCurrentToken();
    let db = this.storageService.getCurrentDB();
    console.log(request)
      if (token) {
        request = request.clone({
          setHeaders: {
           //Authorization: 'Bearer ' + token,
           Token: 'Bearer ' +token,
    
          }
        });
      }
     return next.handle(request)
     .pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {
     
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
      
        return throwError(errorMessage);
      })
    )
   }
  
}
