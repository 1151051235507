import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../../services/storage.service';
import { Router } from '@angular/router';
import { AuthService } from '../../../auth/auth.service';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  providers: [Location, {provide: LocationStrategy, useClass: PathLocationStrategy}],
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  
  username :string;
  public nombre:string;
  rol:string;
  fechareg:string;
  fecha:string;
  minombre:string;
  mialias:string;

  location: Location;
  constructor(location: Location, private storageService:StorageService, private router:Router,private dataService: AuthService) {
  this.location=location;
   }

  ngOnInit() {
    const currentUser = this.dataService.currentUserValue;
    if (currentUser) {
       console.log(currentUser);
       this.username=currentUser.user[0].username;
       this.nombre=currentUser.user[0].nombre;
       this.rol=currentUser.user[0].rol;
       this.fechareg=currentUser.user[0].fechareg;
       this.minombre=currentUser.user[0].minombre;
       this.mialias=currentUser.user[0].mialias;
       this.fecha=currentUser.user[0].fecha;
    }

   
  }

 
  public onLogout(): void{
 
  this.storageService.removeCurrentSession();
  this.dataService.logout();
  }
    
  }

