import { Component, OnInit } from '@angular/core';
import { CuotaService } from '../services/cuota.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { TipoService } from '../services/tipo.service';
import { Tipo } from '../modules/tipo';
import { CreateIngresoComponent } from '../ingresos/create-ingreso/create-ingreso.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { ResumenCuotasComponent } from './resumen-cuotas/resumen-cuotas.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../auth/auth.service';
import { HelpComponent } from '../components/shared/help/help.component';
import { ayuda } from '../modules/global-constants';


@Component({
  selector: 'app-cuotas',
  templateUrl: './cuotas.component.html',
  styleUrls: ['./cuotas.component.css'],
  providers: [DatePipe]
})
export class CuotasComponent implements OnInit {
  cuotas:[];
  Meses:Tipo[];
  selectMeses:Tipo;
  searchText: string;
  fecha:string;
  //fechastring:string;
  btnPagar:boolean=false;

   constructor(private dataService: AuthService,private SpinnerService: NgxSpinnerService,private modalService: NgbModal,private cuotaService:CuotaService,private router:Router, private datePipe: DatePipe,private tipoService:TipoService) { 
    this.fecha = this.datePipe.transform(new Date(),'yyyyMM');
  //  this.fechastring = this.datePipe.transform(new Date(),'MMM yyyy');
  }

  ngOnInit() {
    const currentUser = this.dataService.currentUserValue;
    if (currentUser) {
      currentUser.user[0].menu.forEach(element => {
        if (element.nombre=='Cuota'){
          this.btnPagar=element.new==1; 
       
        }
      });
    }
    this. listCuotas(null);
    this.listMeses();
    
  }
  
  listCuotas(periodo:string) {
    if (periodo==null){
      periodo=this.fecha
    }
 this.SpinnerService.show()
    this.cuotaService.listCuotas(periodo)
    .subscribe( data => {
      console.log(data.result);
      this.cuotas = data.result;
     this.SpinnerService.hide()
      /*302 token expirado*/
       if (data.status==302){
         this.router.navigate(['/login']);
         return;
       }
    },
    error => {
      this.router.navigate(['login']);
      return;
    },
    );
   
  }

  listMeses() {
    this.tipoService.listMeses()
      .subscribe(data => {

        this.Meses = data.result;
        for(let i=0; i<this.Meses.length; i++){
          if (this.Meses[i].id==Number(this.fecha)){
            this.selectMeses=this.Meses[i];
           }
        }
     
        //this.selectMeses.id = this.Meses[0];
        console.log(this.Meses);
        /*302 token expirado*/
        if (data.status == 302) {
          this.router.navigate(['login']);
          return;
        }
      },
      error => {
        this.router.navigate(['login']);
        return;
      },
      );
  }

  onChange() {
    this.listCuotas(String(this.selectMeses.id));
  }

  onModalView(id: number):void {
    const modalRef = this.modalService.open(CreateIngresoComponent, { size: 'md', backdrop: 'static' } );
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.accion = 'Ver';
    modalRef.result.then((result) => {
    console.log(result);
        
    
    }).catch( (result) => {
    console.log(result);
    });
    }

    pagarCuota(id: number) {
      Swal.fire({
        title: 'Advertencia',
        text: "¿Está seguro que pagar la cuota?",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si!'
      }).then((result) => {
        if (result.value) {
          this.cuotaService.pagarCuota(id)
        .subscribe(
          data => this.onChange(),
          error => console.log(error));
        }
      })
    
    } 

    onModalResumen(): void {
      const modalRef = this.modalService.open(ResumenCuotasComponent, { size: 'lg', backdrop: 'static' });
      modalRef.componentInstance.id = -1;
      modalRef.componentInstance.accion = 'Resumen Anual';
      modalRef.result.then((result) => {
        console.log(result);
       
  
      }).catch((result) => {
        console.log(result);
      });
    }
    
    onModalHelp(): void {
      const modalRef = this.modalService.open(HelpComponent, { size: 'md', backdrop: 'static' });
      modalRef.componentInstance.id = ayuda.cuota
        
      modalRef.result.then((result) => {
          
  
      }).catch((result) => {
       
      });
    }
}
