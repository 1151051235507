import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '../modules/apiresponse';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { GlobalConstants } from '../modules/global-constants';

@Injectable({
  providedIn: 'root'
})
export class DocumentoService {
  baseUrl:string = GlobalConstants.apiURL;
  currentUser = this.dataService.currentUserValue;
  constructor(private httpClient: HttpClient, private dataService: AuthService ) { }

  public listDocumentos() : Observable<ApiResponse>{
    return this.httpClient.get<ApiResponse>(this.baseUrl +'/listDocumentos.php');
  }

  public createDocumento (formData: any): Observable<ApiResponse> {
         
    return this.httpClient.post<ApiResponse>(this.baseUrl +'/createDocumento.php', formData)
  }

  public deleteDocumento(id: number): Observable<ApiResponse> {
    return this.httpClient.get<ApiResponse>(this.baseUrl +'/deleteDocumento.php?id='+id+'&usuario='+this.currentUser.user[0].username);
  }
}
