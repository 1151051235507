import { Socio } from './socio';

export class Noticia {
  id:number;
  titulo:string;
  cuerpo:string;
  desde:Date= new Date();
  hasta:Date= new Date();
  activo:number;
  socio: Socio= new Socio();
  usuario:string;
}
