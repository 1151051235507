import { Injectable } from '@angular/core';
import { ApiResponse } from '../modules/apiresponse';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { GlobalConstants } from '../modules/global-constants';

@Injectable({
  providedIn: 'root'
})
export class EgresoService {
  redirectUrl: string;

  baseUrl:string = GlobalConstants.apiURL;

  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'} )
  };
  currentUser = this.dataService.currentUserValue;
  constructor(private httpClient: HttpClient, private dataService: AuthService ) { }

  
  public listEgresos() : Observable<ApiResponse>{
    return this.httpClient.get<ApiResponse>(this.baseUrl +'/listEgresos.php');
  }

  public listIdEgreso(Id: number) : Observable<ApiResponse>{
    return this.httpClient.get<ApiResponse>(this.baseUrl +'/listIdEgreso.php?id='+Id);
  }
  public createEgreso (formData: any): Observable<ApiResponse> {
         
      return this.httpClient.post<ApiResponse>(this.baseUrl +'/createEgreso.php', formData)
     
  }

  public anularEgreso(id: number): Observable<ApiResponse> {
    return this.httpClient.get<ApiResponse>(this.baseUrl +'/anularEgreso.php?id='+id+'&usuario='+this.currentUser.user[0].username);
  }
 
  public getDoc(id) {
    return this.httpClient.get(this.baseUrl +'/getDocEgreso.php?id='+id,{ responseType: 'blob' });
  }
}
