import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiResponse } from '../modules/apiresponse';
import { GlobalConstants } from '../modules/global-constants';
import { Data } from '../modules/Data';


@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  baseUrl:string = GlobalConstants.apiURL;

  constructor(private httpClient: HttpClient) {}

  public listIndicador() : Observable<ApiResponse>{
    return this.httpClient.get<ApiResponse>(this.baseUrl +'/listIndicador.php')
  }

  public listGraficoBarra() : Observable<ApiResponse>{
    return this.httpClient.get<ApiResponse>(this.baseUrl +'/listGraficoBarra.php');
  }

  
}
