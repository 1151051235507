  <!-- <footer class="main-footer" style=" position: fixed; padding-top: 5px;padding-bottom: 5px; font-size: 12px;">
    <div class="pull-right hidden-xs">
      <b>Version</b> 1.0.20
    </div>
    <strong>Copyright &copy; 2020 <a href="#">MiOrganizacion</a>.</strong> Todos los derechos reservados.
  </footer> -->
  <footer class="main-footer" style="padding-top: 5px;padding-bottom: 5px; font-size: 14px;" >
    <div class="row">
      <div class="col-xl-7 col-lg-5 d-none d-xl-block d-lg-block">
        <strong>Copyright &copy; 2020 <a target="_blank" href="https://www.miorganizacion.cl">MiOrganizacion</a>.</strong> <span class="hidden-sm hidden-md">Todos los derechos reservados.</span> <span class="hidden-sm hidden-md"><b>Version</b> 1.0.20</span>
        </div>
    <div class="col-xl-5 col-lg-7 col-md-12 col-xs-12 col-sm-12">
      Si necesitas ayuda escríbenos a: <b><a href="mailto:ayuda@miorganizacion.cl">ayuda@miorganizacion.cl</a></b>
    </div>
<!--     
    <div class="col-md-2 d-none d-xl-block d-lg-block">
     
    </div> -->
  </div>
  </footer>
