export class Carta {
    logo: string;
    nombre: string;
    id: number;
    rut:string;
    lugar: string;
    direccion: string;
    
}

