import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '../modules/apiresponse';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { GlobalConstants } from '../modules/global-constants';

@Injectable({
  providedIn: 'root'
})
export class CuotaService {

  baseUrl:string = GlobalConstants.apiURL;
  currentUser = this.dataService.currentUserValue;
  constructor(private httpClient: HttpClient, private dataService: AuthService ) { }

  public listCuotas(fecha:any) : Observable<ApiResponse>{
    return this.httpClient.get<ApiResponse>(this.baseUrl +'/listCuotas.php?fecha='+fecha);
  }

  public ResumenAnual() : Observable<ApiResponse>{
    return this.httpClient.get<ApiResponse>(this.baseUrl +'/listAnual.php');
  }

  public pagarCuota(id: number): Observable<ApiResponse> {
    return this.httpClient.get<ApiResponse>(this.baseUrl +'/pagarCuota.php?id='+id+'&usuario='+this.currentUser.user[0].username);;
  }
}
