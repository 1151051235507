import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiResponse } from '../modules/apiresponse';
import { Ingreso } from '../modules/ingreso';
import { AuthService } from '../auth/auth.service';
import { GlobalConstants } from '../modules/global-constants';

@Injectable({
  providedIn: 'root'
})
export class IngresoService {
  redirectUrl: string;

  baseUrl:string = GlobalConstants.apiURL;

  httpOptions = {
    headers: new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'} )
  };
  currentUser = this.dataService.currentUserValue;
  constructor(private httpClient: HttpClient, private dataService: AuthService ) { }

  public listIngresos() : Observable<ApiResponse>{
    return this.httpClient.get<ApiResponse>(this.baseUrl +'/listIngresos.php');
  }

  public listIdIngreso(Id: number) : Observable<ApiResponse>{
    return this.httpClient.get<ApiResponse>(this.baseUrl +'/listIdIngreso.php?id='+Id);
  }
  public createIngreso (formData): Observable<ApiResponse> {
    
      return this.httpClient.post<ApiResponse>(this.baseUrl +'/createIngreso.php',formData);
  }

  public anularIngreso(id: number): Observable<ApiResponse> {
    return this.httpClient.get<ApiResponse>(this.baseUrl +'/anularIngreso.php?id='+id+'&usuario='+this.currentUser.user[0].username);
  }
 
  public uploadFile(data): Observable<ApiResponse> {
    let uploadURL = this.baseUrl +'/upload.php';

    return this.httpClient.post<ApiResponse>(uploadURL, data);
  }

  public getDoc(id) {
    return this.httpClient.get(this.baseUrl +'/getDocIngreso.php?id='+id,{ responseType: 'blob' });
  }
}

