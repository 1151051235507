<div class="modal-header">
    <h4 class="modal-title">{{accion}} certficado de residencia</h4>
    <button type="button" class="close" aria-label="Close" (click)="onDismiss('Cross click')">
        <span aria-hidden="true">x</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="resumeForm" autocomplete="off">
        <div class="form-row">

            <div class="form-group col-md-5">
                <label for="inputFecha">Socio</label>
                <div class="input-group">
                    <select class="custom-select" formControlName="socio" (change)="onChange()"
                    [ngClass]="{ 'is-invalid': submitted && f.socio.errors }" class="form-control input-sm" >
                         <option [ngValue]="{'id':0,'nombre':'ninguno'}">NO Socio</option>
                        <option *ngFor="let item of socios ; let i = index" [ngValue]="item">
                            {{item.nombre}}
                        </option>
                        
                    </select>

                </div>
            </div>
        </div>
        <div class="form-row">

            <div class="form-group col-md-2">
                <label for="inputFecha">Rut</label>
                <div class="input-group">
                    <input type="text" name="rut" formControlName="rut" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.rut.errors }" class="form-control input-sm"
                        placeholder="Rut">

                </div>
            </div>
            <div class="form-group col-md-8">
                <label for="inputFecha">Nombre</label>
                <div class="input-group">
                    <input type="text" name="nombre" formControlName="nombre" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }" class="form-control input-sm"
                        placeholder="Nombre">
                </div>
            </div>
        </div>
       
        <div class="form-row">
            <div class="form-group col-md-10">
                <label for="inputFecha">Dirección</label>
                <div class="input-group">

                    <textarea name="direccion" formControlName="direccion" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.direccion.errors }" class="form-control input-sm"
                        placeholder="Dirección"></textarea>

                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-10">
                <label for="inputFecha">Para ser presentado</label>
                <div class="input-group">
                    <textarea name="lugar" formControlName="lugar" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.lugar.errors }" class="form-control input-sm"
                        placeholder="Lugar"></textarea>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="onClose(false)">Cerrar</button>
            <button id="btnGuardar" type="submit" class="btn btn-primary"
                (click)="generatePdf('open')">Generar</button>

        </div>
    </form>
</div>