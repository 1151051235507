import { Tipo } from './tipo';

export class Socio {
      id:number;
      email: string;
      rut: string;
      dv: string;
      numero:number;
      nombre: string;
      apellidos: string;
      profesion: string;
      direccion: string;
      ciudad: string;
      estado: number;
      rol:number;
      obs: string;
      fecnac: Date = new Date();
      fecreg: Date = new Date();
      telefono: number;
      usuario:string;
}
  