import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { StorageService } from '../services/storage.service';
import { AuthService } from './auth.service';


@Injectable({
  providedIn: 'root'
})
export class AuthguardGuard implements CanActivate {
  constructor(private storageService: StorageService,private router: Router,private dataService: AuthService  ) {}

   canActivate() {
     console.log(this.storageService.isAuthenticated());
     if (this.storageService.isAuthenticated()) {
       // logged in so return true
       return true;
     }
     // not logged in so redirect to login page
     this.router.navigate(['/login']);
     return false;
   }

}
