<!-- Left side column. contains the logo and sidebar -->
<aside class="main-sidebar">

    <!-- sidebar: style can be found in sidebar.less -->
    <section class="sidebar">

      <!-- Sidebar user panel (optional) -->
      <div class="user-panel  mt-2 pb-2 mb-2 d-flex">
        <div class="pull-left image">
            <i class="fa fa-user fa-2x fa-inverse"></i>
        </div>
        <div class="pull-left info" style="padding-left: 0px;left: 40px;font-size: 12px;" >
          <p>{{nombre}}</p>
          <!-- Status -->
         
        </div>
      </div>
  

      <!-- Sidebar Menu -->
      <ul class="sidebar-menu" data-widget="tree">
        <li class="header">Menú de Principal</li>
        <!-- Optionally, you can add icons to the links -->
        <li *ngIf="mTablero" routerLinkActive="active">
          <!-- <a href="#"><i class="fa fa-link"></i> <span>Link</span></a> -->
          <a class="nav-link" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <i class="fa fa-dashboard" aria-hidden="true"></i><span>Tablero</span>
          </a>

      </li>
        <li *ngIf="mSocio" routerLinkActive="active">
            <!-- <a href="#"><i class="fa fa-link"></i> <span>Link</span></a> -->
            <a class="nav-link" routerLink="/socios" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                <i class="fa fa-users" aria-hidden="true"></i><span>Socios</span>
            </a>

        </li>
        <li *ngIf="mActa" routerLinkActive="active">
          <a class="nav-link" routerLink="/acta" routerLinkActive="activo" [routerLinkActiveOptions]="{exact: true}">
            <i class="fa fa-book" aria-hidden="true"></i><span>Actas</span>
          </a>
      </li>
      <li *ngIf="mResidencia" routerLinkActive="active">
        <a class="nav-link" routerLink="/residencia" routerLinkActive="activo" [routerLinkActiveOptions]="{exact: true}">
          <i class="fa fa-file" aria-hidden="true"></i><span>Residencia</span>
        </a>
    </li>
    <li *ngIf="mCuota" routerLinkActive="active">
      <a class="nav-link" routerLink="/cuotas" routerLinkActive="activo" [routerLinkActiveOptions]="{exact: true}">
        <i class="fa fa-usd" aria-hidden="true"></i><span>Cuotas</span>
      </a>
  </li>
      
          <li *ngIf="mIngreso" routerLinkActive="active">
            <a class="nav-link" routerLink="/ingresos" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <i class="fa fa-plus-square" aria-hidden="true"></i><span>Ingresos</span>
            </a>
        </li>
          <li *ngIf="mEgreso" routerLinkActive="active">
            <a class="nav-link" routerLink="/egresos" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <i class="fa fa-minus-square" aria-hidden="true"></i><span>Egresos</span>
            </a>
        </li>

        <li *ngIf="mProyecto" routerLinkActive="active">
            <a class="nav-link" routerLink="/proyectos" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <i class="fa fa-list-ol" aria-hidden="true"></i><span>Proyectos</span>
            </a>
        </li>
        <li *ngIf="mItem" routerLinkActive="active">
            <a class="nav-link" routerLink="/items" routerLinkActive="activo" [routerLinkActiveOptions]="{exact: true}">
              <i class="fa fa-list" aria-hidden="true"></i><span>Items</span>
                
            </a>
        </li>
          
    <li *ngIf="mNoticia" routerLinkActive="active">
      <a class="nav-link" routerLink="/noticia" routerLinkActive="activo" [routerLinkActiveOptions]="{exact: true}">
        <i class="fa fa-newspaper-o" aria-hidden="true"></i><span>Noticias</span>
      </a>
  </li>
      <li *ngIf="mOrganizacion" routerLinkActive="active">
        <a class="nav-link" routerLink="/about" routerLinkActive="activo" [routerLinkActiveOptions]="{exact: true}">
          <i class="fa fa-cogs" aria-hidden="true"></i><span>Mi Organización</span>
        </a>
    </li>
      </ul>
      <!-- /.sidebar-menu -->
    </section>
    <!-- /.sidebar -->
  </aside>

