import { Component, OnInit } from '@angular/core';
import { Proyecto } from '../modules/proyecto';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { ProyectoService } from '../services/proyecto.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateProyectosComponent } from './create-proyectos/create-proyectos.component';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../auth/auth.service';
import { HelpComponent } from '../components/shared/help/help.component';
import { ayuda } from '../modules/global-constants';

@Component({
  selector: 'app-proyectos',
  templateUrl: './proyectos.component.html',
  styleUrls: ['./proyectos.component.css']
})
export class ProyectosComponent implements OnInit {
  proyectos:Proyecto[];
  p: number = 1;
  btnNew:boolean=false;
btnEdit:boolean=false;
btnDel:boolean=false;
  constructor(private dataService: AuthService,private SpinnerService: NgxSpinnerService,private router:Router,private proyectoService: ProyectoService, private modalService: NgbModal,public activeModal: NgbActiveModal) { }

  ngOnInit() {
    const currentUser = this.dataService.currentUserValue;
    if (currentUser) {
      currentUser.user[0].menu.forEach(element => {
        if (element.nombre=='Proyecto'){
          this.btnNew=element.new==1; 
          this.btnEdit=element.edit==1; 
          this.btnDel=element.del==1; 
        }
      });
    }
    this.listProyectos();
  }
  
  listProyectos() {
    this.SpinnerService.show()
    this.proyectoService.listProyectos()
    .subscribe( data => {
      console.log(data.result);
      this.proyectos = data.result;
     this.SpinnerService.hide()
      /*302 token expirado*/
       if (data.status==302){
         this.router.navigate(['/login']);
         return;
       }
    },
    error => {
      this.router.navigate(['login']);
      return;
    },);
   
  }

 

  onModalRequest():void {
    const modalRef = this.modalService.open(CreateProyectosComponent, { size: 'md', backdrop: 'static' } );
    modalRef.componentInstance.id = -1;
    modalRef.componentInstance.accion = 'Nuevo';
    modalRef.result.then((result) => {
    console.log(result);

    if (result){
      this.listProyectos();
    }
    
    }).catch( (result) => {
    console.log(result);
    });
    }

    onModalEdit(id: number):void {
      const modalRef = this.modalService.open(CreateProyectosComponent, { size: 'md', backdrop: 'static' } );
      modalRef.componentInstance.id = id;
      modalRef.componentInstance.accion = 'Editar';
      modalRef.result.then((result) => {
      console.log(result);
      
      if (result){
        this.listProyectos();
      }
      
      }).catch( (result) => {
      console.log(result);
      });
      }
  
      onModalView(id: number):void {
       const modalRef = this.modalService.open(CreateProyectosComponent, { size: 'md', backdrop: 'static' } );
       modalRef.componentInstance.id = id;
       modalRef.componentInstance.accion = 'Ver';
       modalRef.result.then((result) => {
       console.log(result);
       
       }).catch( (result) => {
       console.log(result);
       });
       }

       onModalHelp(): void {
        const modalRef = this.modalService.open(HelpComponent, { size: 'md', backdrop: 'static' });
        modalRef.componentInstance.id = ayuda.proyecto
          
        modalRef.result.then((result) => {
            
    
        }).catch((result) => {
         
        });
      }

      deleteProyecto(proyecto: Proyecto) {
        console.log(proyecto.id);
        Swal.fire({
          title: 'Advertencia',
          text: "¿Está seguro que desea anular el proyecto "+proyecto.id+"?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si!'
        }).then((result) => {
          if (result.value) {
            this.proyectoService.deleteProyecto(proyecto.id)
          .subscribe(
            data => this.listProyectos(),
            error => console.log(error));
          }
        })
      
      } 
    }
