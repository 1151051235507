<div class="modal-header">
    <h4 class="modal-title">Ayuda {{nombre}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="onDismiss('Cross click')">
        <span aria-hidden="true">x</span>
    </button> 
</div>
<div class="modal-body">
    <div [innerHTML]="texto"></div>
</div>

