
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <h1>
      Noticias
      <small style="float: right;margin-right: 135px"><button type="button" class="btn text-primary btn-sm d-none d-lg-table-cell accion" 
        (click)="onModalHelp()">Ayuda</button></small>
    </h1>
    <ol class="breadcrumb">
      <li><a href="#"><i class="fa fa-dashboard"></i> Menu</a></li>
      <li class="active">Noticias</li>
    </ol>
  </section>

  <!-- Main content -->
  <section class="content" >

    <div class="row">
      <div class="col-sm-12">
        <div class="box">
          <div class="box-header">
            <div class="row">
              <div class="col-sm-6">
                 <pagination-controls (pageChange)="p = $event" 
                autoHide="true"
                        responsive="true"
                previousLabel="Anterior"
                nextLabel="Próxima">
                  </pagination-controls> 
              </div>
              <div class="col-sm-6">
            <button *ngIf="btnNew" class="btn btn-outline-success text-right btn-sm"  style="float: right;" (click)="onModalRequest()"><i style="margin-right: 5px;" class="fa fa-plus"></i>Agregar</button>
          </div>
            </div>
          </div>

          <div class="box-body">

            <table class="table table-bordered table-hover table-sm">
              <thead>
                <tr>
              
                  <th scope="col" >Titulo</th>
                  <th scope="col">Cuerpo</th>
                  <th scope="col" style="width:10%;">Desde</th>
                  <th scope="col" style="width:10%;">Hasta</th>
                  <th scope="col" class="d-none d-lg-table-cell" style="width:20%;" >Autor</th>
                  <th scope="col" style="width:10%;" >Acción</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let noticia of noticias  | paginate: { itemsPerPage: 9, currentPage: p }">
                
                  <td>{{noticia.titulo }}</td>
                  <td>{{noticia.cuerpo }}</td>
                  <td >{{noticia.desde   }}</td>
                  <td>{{noticia.hasta }}</td>
                  <td class="d-none d-lg-table-cell">{{noticia.socio.nombre}}</td>
           
                  <td >
                
                    <button type="button" class="btn text-primary  btn-sm d-none d-lg-table-cell accion" (click)="onModalView(noticia.id)"><i class="fa fa-eye"></i></button>
                    <button *ngIf="btnEdit" type="button" class="btn text-success  btn-sm accion" (click)="onModalEdit(noticia.id)"><i class="fa fa-edit"></i></button>
                    <button *ngIf="btnDel" type="button" class="btn text-danger   btn-sm accion" (click)="deleteNoticia(noticia)"><i class="fa fa-trash"></i></button>
                    
                  </td>
                </tr>
            
              </tbody>
            </table>
            <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
              <p style="color: white">Cargando datos... </p>  
              </ngx-spinner>   
          </div>
        </div>
      </div>
    </div>


  </section>
  <!-- /.content -->


   


