import { Component, OnInit } from '@angular/core';
import { OrganizacionService } from '../services/organizacion.service';
import { Organizacion, Autoridad, Tipo } from '../modules/organizacion';
import { Router } from '@angular/router';
import { CuotaService } from '../services/cuota.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HelpComponent } from '../components/shared/help/help.component';
import { ayuda } from '../modules/global-constants';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  [x: string]: any;
organizacion= new Organizacion();
cuota= new Tipo();
autoridad= new Autoridad();
  constructor(private SpinnerService: NgxSpinnerService,private organizacionService:OrganizacionService, private router:Router,private modalService: NgbModal,public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.listOrganizacion();
    this.listCuota();
    this.listAutoridad();
  }

  listOrganizacion() {
    this.SpinnerService.show
    this.organizacionService.listOrganizacion()
    .subscribe( data => {
      console.log(data.result);
       this.organizacion=data.result;  
     this.SpinnerService.hide
    
      /*302 token expirado*/
       if (data.status==302){
         this.router.navigate(['/login']);
         return;
       }
    });
   }

   listCuota() {
    this.SpinnerService.show
    this.organizacionService.listCuota()
    .subscribe( data => {
      console.log(data.result);
       this.cuota=data.result;  
       this.SpinnerService.hide
      /*302 token expirado*/
       if (data.status==302){
         this.router.navigate(['/login']);
         return;
       }
    });
   }

   listAutoridad() {
    this.SpinnerService.show
    this.organizacionService.listAutoridad()
    .subscribe( data => {
      console.log(data.result);
       this.autoridad=data.result;  
       this.SpinnerService.hide
      /*302 token expirado*/
       if (data.status==302){
         this.router.navigate(['/login']);
         return;
       }
    });
   }

   onModalHelp(): void {
    const modalRef = this.modalService.open(HelpComponent, { size: 'md', backdrop: 'static' });
    modalRef.componentInstance.id = ayuda.organizacion
      
    modalRef.result.then((result) => {
        

    }).catch((result) => {
     
    });
  }

}
