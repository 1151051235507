import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SociosComponent} from './socios/socios.component';
import { LoginComponent } from './login/login.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { IngresosComponent } from './ingresos/ingresos.component';
import { HomeComponent } from './components/shared/home/home.component';
import {AuthguardGuard} from './auth/authguard.guard'
import { HomeLayoutComponent } from './layouts/home-layout.component';
import { LoginLayoutComponent } from './layouts/login-layout.component';
import { EgresosComponent } from './egresos/egresos.component';
import { ProyectosComponent } from './proyectos/proyectos.component';
import { ItemsComponent } from './items/items.component';
import { ViewDocComponent } from './components/shared/view-doc/view-doc.component';
import { CuotasComponent } from './cuotas/cuotas.component';
import { ResidenciasComponent } from './residencia/residencias/residencias.component';
import { AboutComponent } from './about/about.component';
import { ActasComponent } from './actas/actas.component';
import { NoticiasComponent } from './noticias/noticias.component';

const routes: Routes = [
 
  {
    path: '',                       // {1}
    component: HomeLayoutComponent,
    canActivate: [AuthguardGuard],       // {2}
    children: [
      {
        path: '',
        component: HomeComponent   // {3}
      },
      {
        path: 'socios',
        component: SociosComponent   // {3}
      },
      {
        path: 'ingresos',
        component: IngresosComponent   // {3}
      },
      {
        path: 'egresos',
        component: EgresosComponent   // {3}
      },
      {
        path: 'proyectos',
        component: ProyectosComponent   // {3}
      },
      {
        path: 'items',
        component: ItemsComponent   // {3}
      },
      {
        path: 'cuotas',
        component: CuotasComponent   // {3}
      },
      {
        path: 'viewDoc',
        component: ViewDocComponent   // {3}
      },
      {
        path: 'residencia',
        component: ResidenciasComponent   // {3}
      },
      {
        path: 'acta',
        component: ActasComponent   // {3}
      },
      {
        path: 'noticia',
        component: NoticiasComponent   // {3}
      },
      {
        path: 'about',
        component: AboutComponent   // {3}
      },
    ]
  },
  {
    path: '',
    component: LoginLayoutComponent, // {4}
    children: [
      {
        path: 'login',
        component: LoginComponent   // {5}
      }
    ]
  }
  //  { path: '', component: HomeComponent, canActivate: [AuthguardGuard] },
  
  //  { path: 'login', component: LoginComponent },
  //  { path: 'ingresos', component: IngresosComponent/*, canActivate: [AuthguardGuard]*/ },
  //  { path: 'socios', component: SociosComponent/*, canActivate: [AuthguardGuard]*/ },
  //  { path: '**', redirectTo: ''}
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
