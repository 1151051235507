import { Proyecto } from './proyecto';
import { Tipoitem } from './tipoitem';

export class Ingreso {
    id:number;
    fecha: Date= new Date();
    monto: number;
    obs: string;
    estado: number;
    proyecto: Proyecto[];
    items: Tipoitem[];
    conDoc:boolean;
    usuario:string;
}
