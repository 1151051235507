import { Component, OnInit } from '@angular/core';
import { ActaService } from '../services/acta.service';
import { Router } from '@angular/router';
import { CreateActaComponent } from './create-acta/create-acta.component';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { Acta } from '../modules/organizacion';
import { ViewDocComponent } from '../components/shared/view-doc/view-doc.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../auth/auth.service';
import { HelpComponent } from '../components/shared/help/help.component';
import { ayuda } from '../modules/global-constants';

@Component({
  selector: 'app-actas',
  templateUrl: './actas.component.html',
  styleUrls: ['./actas.component.css']
})
export class ActasComponent implements OnInit {
actas:Acta[];
p: number = 1;
btnNew:boolean=false;
btnEdit:boolean=false;
btnDel:boolean=false;
  constructor(private dataService: AuthService,private SpinnerService: NgxSpinnerService,private actaService: ActaService, private router:Router,private modalService: NgbModal,public activeModal: NgbActiveModal ) { }

  ngOnInit(): void {

    const currentUser = this.dataService.currentUserValue;
    if (currentUser) {
      currentUser.user[0].menu.forEach(element => {
        if (element.nombre=='Acta'){
          this.btnNew=element.new==1; 
          this.btnEdit=element.edit==1; 
          this.btnDel=element.del==1; 
        }
      });
    }
    this.listActas();
  }
  
  listActas() {
    this.SpinnerService.show
    this.actaService.listActas()
    .subscribe( data => {
      console.log(data.result);
      this.actas = data.result;
     this.SpinnerService.hide
      /*302 token expirado*/
       if (data.status==302){
         this.router.navigate(['/login']);
         return;
       }
    });
   
  }
  onModalRequest():void {
    const modalRef = this.modalService.open(CreateActaComponent, { size: 'lg', backdrop: 'static'  } );
    modalRef.componentInstance.id = -1;
    modalRef.componentInstance.accion = 'Nuevo';
    modalRef.result.then((result) => {
    console.log(result);

    if (result){
      this.listActas();
    }
    
    }).catch( (result) => {
    console.log(result);
    });
    }

    onModalEdit(id: number):void {
      const modalRef = this.modalService.open(CreateActaComponent, { size: 'lg', backdrop: 'static' } );
      modalRef.componentInstance.id = id;
      modalRef.componentInstance.accion = 'Editar';
      modalRef.result.then((result) => {
      console.log(result);
      
      if (result){
        this.listActas();
      }
      
      }).catch( (result) => {
      console.log(result);
      });
      }
  
      onModalView(id: number):void {
       const modalRef = this.modalService.open(CreateActaComponent, { size: 'lg', backdrop: 'static' } );
       modalRef.componentInstance.id = id;
       modalRef.componentInstance.accion = 'Ver';
       modalRef.result.then((result) => {
       console.log(result);
       
       }).catch( (result) => {
       console.log(result);
       });
       }

       onModalViewDoc(id:number,mime:string):void {
        const modalRef = this.modalService.open(ViewDocComponent, { size: 'lg', backdrop: 'static' } );
        modalRef.componentInstance.id = id;
        modalRef.componentInstance.tipo = 4;
        modalRef.componentInstance.mime = mime;
        modalRef.result.then((result) => {
             
        }).catch( (result) => {
        console.log(result);
        });
        }

        onModalHelp(): void {
          const modalRef = this.modalService.open(HelpComponent, { size: 'md', backdrop: 'static' });
          modalRef.componentInstance.id = ayuda.acta
            
          modalRef.result.then((result) => {
              
      
          }).catch((result) => {
           
          });
        }

      deleteActa(acta: Acta) {
        console.log(acta.id);
        Swal.fire({
          title: 'Advertencia',
          text: "¿Está seguro que desea anular el acta "+acta.id+"?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si!'
        }).then((result) => {
          if (result.value) {
            this.actaService.deleteActa(acta.id)
          .subscribe(
            data => this.listActas(),
            error => console.log(error));
          }
        })
      
      } 
}
