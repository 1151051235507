import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Egreso } from '../modules/egreso';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EgresoService } from '../services/egreso.service';
import Swal from 'sweetalert2';
import { CreateEgresoComponent } from './create-egreso/create-egreso.component';
import { ViewDocComponent } from '../components/shared/view-doc/view-doc.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../auth/auth.service';
import { ExcelService } from '../services/excel.service';
import { HelpComponent } from '../components/shared/help/help.component';
import { ayuda } from '../modules/global-constants';

@Component({
  selector: 'app-egresos',
  templateUrl: './egresos.component.html',
  styleUrls: ['./egresos.component.css']
})
export class EgresosComponent implements OnInit {
  egresos:Egreso[];
  p: number = 1;
  btnNew:boolean=false;
btnEdit:boolean=false;
btnDel:boolean=false;
  constructor(private excelService:ExcelService,private dataService: AuthService,private SpinnerService: NgxSpinnerService,private router:Router,private egresoService: EgresoService, private modalService: NgbModal,public activeModal: NgbActiveModal) { }

  
    ngOnInit() {
      const currentUser = this.dataService.currentUserValue;
      if (currentUser) {
        currentUser.user[0].menu.forEach(element => {
          if (element.nombre=='Egreso'){
            this.btnNew=element.new==1; 
            this.btnEdit=element.edit==1; 
            this.btnDel=element.del==1; 
          }
        });
      }
      this.listEgresos();
     }
     
     listEgresos() {
       this.SpinnerService.show()
       this.egresoService.listEgresos()
       .subscribe( data => {
         console.log(data.result);
         this.egresos = data.result;
        this.SpinnerService.hide()
         /*302 token expirado*/
          if (data.status==302){
            this.router.navigate(['/login']);
            return;
          }
       },
       error => {
         this.router.navigate(['login']);
         return;
       },
       );
      
     }
   
   
    onModalViewDoc(id:number,mime:string):void {
      const modalRef = this.modalService.open(ViewDocComponent, { size: 'lg', backdrop: 'static' } );
      modalRef.componentInstance.id = id;
      modalRef.componentInstance.tipo = 2;
      modalRef.componentInstance.mime = mime;
      modalRef.result.then((result) => {
           
      }).catch( (result) => {
      console.log(result);
      });
      }
   
     onModalRequest():void {
       const modalRef = this.modalService.open(CreateEgresoComponent, { size: 'lg', backdrop: 'static' } );
       modalRef.componentInstance.id = -1;
       modalRef.componentInstance.accion = 'Nuevo';
       modalRef.result.then((result) => {
       console.log(result);
   
       if (result){
         this.listEgresos();
       }
       
       }).catch( (result) => {
       console.log(result);
       });
       }
   
       onModalEdit(id: number):void {
         const modalRef = this.modalService.open(CreateEgresoComponent, { size: 'lg', backdrop: 'static'} );
         modalRef.componentInstance.id = id;
         modalRef.componentInstance.accion = 'Editar';
         modalRef.result.then((result) => {
         console.log(result);
         
         if (result){
           this.listEgresos();
         }
         
         }).catch( (result) => {
         console.log(result);
         });
         }
     
         onModalView(id: number):void {
          const modalRef = this.modalService.open(CreateEgresoComponent, { size: 'lg', backdrop: 'static' } );
          modalRef.componentInstance.id = id;
          modalRef.componentInstance.accion = 'Ver';
          modalRef.result.then((result) => {
          console.log(result);
          
          }).catch( (result) => {
          console.log(result);
          });
          }

          onModalHelp(): void {
            const modalRef = this.modalService.open(HelpComponent, { size: 'md', backdrop: 'static' });
            modalRef.componentInstance.id = ayuda.egreso
              
            modalRef.result.then((result) => {
                
        
            }).catch((result) => {
             
            });
          }

         anularEgreso(egreso: Egreso) {
           console.log(egreso.id);
           Swal.fire({
             title: 'Advertencia',
             text: "¿Está seguro que desea anular el ingreso "+egreso.id+"?",
             icon: 'warning',
             showCancelButton: true,
             confirmButtonColor: '#3085d6',
             cancelButtonColor: '#d33',
             confirmButtonText: 'Si!'
           }).then((result) => {
             if (result.value) {
               this.egresoService.anularEgreso(egreso.id)
             .subscribe(
               data => this.listEgresos(),
               error => console.log(error));
             }
           })
         
         } 

         exportAsXLSX():void {
          var dataTemp=[];
   
          this.egresos.forEach((Row, index) => {
            var dataRow  = [];
   
            dataRow['fecha']=Row.fecha;
            dataRow['monto']=Row.monto;
            dataRow['obs']=Row.obs
            dataRow['proyecto']=Row.proyecto[0]['nombre']
            dataRow['item']=Row.items[0]['nombre']
           
            
            dataTemp.push(dataRow)
          });  
          this.excelService.exportAsExcelFile(dataTemp, 'Egresos');
       }
}
