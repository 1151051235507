export class Documento {
    id :number;	
    fecha:Date; 	
    numero:number; 	
    codigo:string; 	
    archivo:Blob; 	
    activo:number; 	
    tipo:number; 	
    socio:number;
    nombre: string;
    usuario:string;
}