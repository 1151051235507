
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <h1>
      Cuotas
      <small style="float: right;margin-right: 135px"><button type="button" class="btn text-primary btn-sm d-none d-lg-table-cell accion" 
        (click)="onModalHelp()">Ayuda</button></small>
    </h1>

    <ol class="breadcrumb">
      <li><a href="#"><i class="fa fa-dashboard"></i> Menu</a></li>
      <li class="active">Cuotas</li>
    </ol>
  </section>

  <!-- Main content -->
  <section class="content" >
    <div class="row">
      <div class="col-12">
        <div class="">
          <div class="row">
            <div class="col-sm-2">
              <label>Buscar:<input type="search" [(ngModel)]="searchText" class="form-control input-sm" placeholder=""
                  aria-controls="example1"></label>
            </div>
            <div class="col-sm-2">
              <label>Mes: <select class="custom-select" [(ngModel)]="selectMeses" (change)="onChange()" >
                <option *ngFor="let mes of Meses ; let i = index" [ngValue]="mes">
                  {{Meses[i].nombre}}
                </option>
              </select></label>
            </div>
            <div class="col-sm-5">
              <button class="btn btn-info btn-sm" style="float: left; margin-top: 30px;"
              (click)="onModalResumen()" ><i style="margin-right: 5px;" class="fa fa-search"></i></button>
            </div>
          </div>

          <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="10px" fxLayoutAlign="flex-start">

            <!-- dummy loop -->
         
            <ng-container *ngFor="let cuota of cuotas | filter: searchText : 'nombre'">

              <!-- <app-card fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%"></app-card> -->
              <div class="text-center card-box" style="margin-right: 10px;padding-left: 10px;padding-right: 10px;font-size: small;">
                <div class="member-card">
                  <div class="">
                    <h4>{{ cuota.nombre }}</h4>
                    <p class="text-muted">@{{cuota.cargo.nombre}} <span>| </span><span [ngClass]="{'text-pink':cuota.estado.id==0,'text-green':cuota.estado.id==1}">{{cuota.estado.nombre}}</span>
                      <button *ngIf="cuota.ingreso!=0" type="button" style ="padding-top: 0px;padding-bottom: 0px;" class="btn text-secondary  btn-sm  accion" (click)="onModalView(cuota.ingreso)" >
                        <i class="fa fa-search-plus"></i>
                      </button>
                    </p>
                    <h4>${{cuota.monto | number}}</h4>
                  </div>
                  <button *ngIf="btnPagar" type="button" (click)="pagarCuota(cuota.id)" [disabled]="cuota.estado.id==1" [ngClass]="{'btn-outline-primary':cuota.estado.id==0,'btn-outline-secondary':cuota.estado.id==1}" class="btn  mt-0 btn-rounded waves-effect w-md waves-light">Pagar</button>
                  <div class="mt-0">
                    <div class="row">
                      <div class="col-6">
                        <div class="mt-0">
                          <h4>{{cuota.resumen[0].pagadas | number}}</h4>
                          <p class="mb-0 text-muted">Pagadas</p>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="mt-0">
                          <h4>{{cuota.resumen[0].pendientes | number}}</h4>
                          <p class="mb-0 text-muted">Pendientes</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </ng-container>
            <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
              <p style="color: white">Cargando datos... </p>  
              </ngx-spinner>   
          </div>
        </div>
      </div>

    </div>
  </section>
  <!-- /.content -->
