<div class="modal-header">
  <h4 class="modal-title">{{accion}} ingreso</h4>
  <button type="button" class="close" aria-label="Close" (click)="onDismiss('Cross click')">
    <span aria-hidden="true">x</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="formIngreso" autocomplete="off">
    <div class="form-row">

      <div class="form-group col-md-6">
        <label for="inputFecha">Fecha.</label>
        <div class="input-group">
          <input formControlName="fecha" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.fecha.errors }"
            placeholder="yyyy-mm-dd" name="fec" ngbDatepicker #fec="ngbDatepicker">
          <div class="input-group-append">
            <button class="btn btn-secondary calendar" *ngIf="!isReadOnly" (click)="fec.toggle()"
              type="button"></button>
          </div>
        </div>
      </div>
      <div class="form-group col-md-6">
        <label for="inputMonto">Monto</label>
        <input type="text" name="monto" formControlName="monto" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.monto.errors }" class="form-control input-sm" placeholder="Monto">
          <small class="form-text text-muted" *ngIf="f.monto.hasError('pattern')">Monto no válido</small>
      </div>
    </div>
  
    <div class="form-row">
      <div class="form-group col-md-12">
        <label for="inputApellidos">Observación</label>
        <textarea  name="obs" formControlName="obs" [ngClass]="{ 'is-invalid': submitted && f.obs.errors }" class="form-control input-sm" placeholder="Obs"></textarea>
        <small class="form-text text-muted" *ngIf="f.obs.hasError('minlength') ||f.obs.hasError('maxlength')">Excede el límite de caracteres permitidos</small>
        </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="inputproyecto">Proyecto</label>
        <select class="custom-select" formControlName="proyecto" [ngClass]="{ 'is-invalid': submitted && f.proyecto.errors }">
          <option selected>seleccione...</option>
          <option *ngFor="let item of proyectos ; let i = index" [value]="proyectos[i].id">
            {{proyectos[i].nombre}}
          </option>
        </select>
      </div>
      <div class="form-group col-md-6">
        <label for="inputItem">Item</label>
        <select class="custom-select" formControlName="item" [ngClass]="{ 'is-invalid': submitted && f.item.errors }">
          <option selected>seleccione...</option>
          <option *ngFor="let item of items ; let i = index" [value]="items[i].id">
            {{items[i].nombre}}
          </option>
        </select>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="inputArchivo">Archivo</label>
        <input type="file" name="archivo" (change)="fileProgress($event)"  *ngIf="!isReadOnly" >
        <small class="form-text text-danger">{{imageError}}</small>
        <br><code style="color: black;" *ngIf="verDoc">Documento-><a (click)="onModalViewDoc()" class="btn btn-default btn-xs" uib-tooltip="Ver"><span class="fa fa-file-pdf-o" aria-hidden="true"></span></a></code> 
      </div>
      
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="onClose(false)">Cerrar</button>
      <button id="btnGuardar" *ngIf="!isReadOnly" type="submit" class="btn btn-primary"
        (click)="crearIngreso(formIngreso.value)">Guardar</button>

    </div>
  </form>
</div>