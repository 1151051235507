import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiResponse } from '../modules/apiresponse';
import { GlobalConstants } from '../modules/global-constants';

@Injectable({
  providedIn: 'root'
})
export class OrganizacionService {
  baseUrl:string = GlobalConstants.apiURL;
  constructor(private httpClient: HttpClient ) { }

  public listOrganizacion() : Observable<ApiResponse>{
    return this.httpClient.get<ApiResponse>(this.baseUrl +'/listOrganizacion.php');
  }

  public listCuota() : Observable<ApiResponse>{
    return this.httpClient.get<ApiResponse>(this.baseUrl +'/listCuota.php');
  }
  public listAutoridad() : Observable<ApiResponse>{
    return this.httpClient.get<ApiResponse>(this.baseUrl +'/listAutoridad.php');
  }
}
