import { Injectable } from '@angular/core';
import { GlobalConstants } from '../modules/global-constants';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { Observable } from 'rxjs';
import { ApiResponse } from '../modules/apiresponse';

@Injectable({
  providedIn: 'root'
})
export class HelpService {
  redirectUrl: string;

  baseUrl:string = GlobalConstants.apiURL;

  httpOptions = {
    headers: new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'} )
  };
  currentUser = this.dataService.currentUserValue;
  constructor(private httpClient: HttpClient, private dataService: AuthService ) { }

  public listId(Id: number) : Observable<ApiResponse>{
    return this.httpClient.get<ApiResponse>(this.baseUrl +'/listAyuda.php?id='+Id);
  }
}
