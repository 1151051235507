
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <h1>
      Actas
      <small style="float: right;margin-right: 135px"><button type="button" class="btn text-primary btn-sm d-none d-lg-table-cell accion" 
        (click)="onModalHelp()">Ayuda</button></small>
    </h1>
    <ol class="breadcrumb">
      <li><a href="#"><i class="fa fa-dashboard"></i> Menu</a></li>
      <li class="active">Actas</li>
    </ol>
  </section>

  <!-- Main content -->
  <section class="content" style="padding-left: 30px;">

    <div class="row">
      <div class="col-sm-12">
        <div class="box">
          <div class="box-header">
            <div class="row">
              <div class="col-sm-6">
                <pagination-controls (pageChange)="p = $event" 
                autoHide="true"
                        responsive="true"
                previousLabel="Anterior"
                nextLabel="Próxima">
                  </pagination-controls> 
              </div>
              <div class="col-sm-6">
                <button *ngIf="btnNew" class="btn btn-outline-success text-right btn-sm" style="float: right;"
                  (click)="onModalRequest()"><i style="margin-right: 5px;" class="fa fa-plus"></i>Agregar</button>
              </div>
            </div>
          </div>

          <div class="box-body">

            <table class="table table-bordered table-hover table-sm">
              <thead>
                <tr>
                  <!-- <th scope="col">#</th> -->
                  <th scope="col">Fecha</th>
                  <th scope="col">Número</th>
                  <th scope="col">Detalle</th>
                  <th scope="col">Acuerdo</th>
                  <th scope="col" class="d-none d-lg-table-cell">Invitados</th>
                  <th scope="col">Acción</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let acta of actas  | paginate: { itemsPerPage: 9, currentPage: p }">
                  <!-- <td>{{acta.id}}</td> -->
                  <td>{{acta.fecha}}</td>
                  <td>{{acta.numero}}</td>
                  <td>{{acta.detalle}}</td>
                  <td>{{acta.acuerdo}}</td>
                  <td class="d-none d-lg-table-cell">{{acta.invitados}}</td>
                  <td>

                    <button type="button" class="btn text-primary btn-sm d-none d-lg-table-cell accion" (click)="onModalView(acta.id)"><i
                        class="fa fa-eye"></i></button>
                    <button *ngIf="btnEdit" type="button" class="btn text-success btn-sm accion" (click)="onModalEdit(acta.id)"><i
                        class="fa fa-edit"></i></button>
                    <button *ngIf="btnDel" type="button" class="btn text-danger  btn-sm accion" (click)="deleteActa(acta)"><i
                        class="fa fa-trash"></i></button>
                        <button *ngIf="acta.doc!=0" type="button" class="btn text-secondary   btn-sm accion" (click)="onModalViewDoc(acta.id,acta.mime)" ><i class="fa fa-file"></i></button>
                  </td>
                </tr>

              </tbody>
            </table>
            <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
              <p style="color: white">Cargando datos... </p>  
              </ngx-spinner>   
          </div>
        </div>
      </div>
    </div>


  </section>
  <!-- /.content -->

