  <!-- Main Header -->
  <header class="main-header">

    <!-- Logo -->
    <a href="#" class="logo">
      <!-- mini logo for sidebar mini 50x50 pixels -->
      <span class="logo-mini"><b>{{mialias}}</b></span>
      <!-- logo for regular state and mobile devices -->
      <span class="logo-lg"><b>{{minombre}}</b></span>
    </a>

    <!-- Header Navbar -->
    <nav class="navbar navbar-static-top" role="navigation" style="height: 51px;" >
      <!-- Sidebar toggle button-->
      <a href="#" class="sidebar-toggle" data-toggle="push-menu" role="button" style="padding-top: 5px;">
        <span class="sr-only">Toggle navigation</span>
      </a>
      <!-- Navbar Right Menu -->
     
      <div class="navbar-custom-menu">
        <ul class="nav navbar-nav">


          
          <!-- User Account Menu -->
          <li class="dropdown user user-menu">
            <!-- Menu Toggle Button -->
            <a href="#" class="dropdown-toggle" data-toggle="dropdown">
              <!-- The user image in the navbar-->
              <!-- <img src="assets/dist/img/user2-160x160.jpg" class="user-image" alt="User Image"> -->
              <!-- hidden-xs hides the username on small devices so only the image appears. -->
              <i class="fa fa-user"></i><span class="hidden-xs">{{username}}</span>
            </a>
            <ul class="dropdown-menu">
              <!-- The user image in the menu -->
              <li class="user-header">
                <!-- <img src="assets/dist/img/user2-160x160.jpg" class="img-circle" alt="User Image"> -->
                <i class="fa fa-user  fa-3x"></i>
                <p>
                  {{nombre}} - {{rol}}
                  <small>Miembro desde {{fechareg}}</small>
                </p>
                <p>
                
                  <small>Último acceso {{fecha}}</small>
                </p>
              </li>
              <!-- Menu Footer-->
              <li class="user-footer">
                <div class="pull-right">
                  <a [routerLink]="" (click)="onLogout()" class="btn btn-default btn-flat"><i class="fa fa-sign-out"></i>&nbsp;Cerrar Sesión</a>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  </header>
<!-- <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <h2 style="color: white;">Miclub</h2>
  
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">

      <li class="nav-item">
        <a class="nav-link" routerLink="/socios" routerLinkActive="activo" [routerLinkActiveOptions]="{exact: true}">Socios</a>
      </li>

      <li class="nav-item dropdown" >
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false" [ngClass]="{activo: (location.path() == '/ingresos' || location.path() == '/egresos') }">
          Movimientos
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" routerLink="/ingresos">Ingresos</a>
          <a class="dropdown-item" routerLink="/egresos">Egresos</a>

        </div>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/proyectos" routerLinkActive="activo" [routerLinkActiveOptions]="{exact: true}" >Proyectos</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/items" routerLinkActive="activo" [routerLinkActiveOptions]="{exact: true}">Items</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/cuotas" routerLinkActive="activo" [routerLinkActiveOptions]="{exact: true}">Cuotas</a>
      </li>

    </ul>
    <form class="form-inline my-2 my-lg-0">
      <div class="btn-group">
        <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false">
          <i class="fa fa-user"></i>&nbsp;{{username}}
        </button>
        <ul class="dropdown-menu">
          <li>
            <a [routerLink]="" (click)="onLogout()"><i class="fa fa-lock"></i>&nbsp;Cerrar Sesión</a></li>
        </ul>
      </div>
    </form>
  </div>
</nav> -->