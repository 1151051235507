
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <h1>
      Socios
      <small style="float: right;margin-right: 135px"><button type="button" class="btn text-primary btn-sm d-none d-lg-table-cell accion" 
        (click)="onModalHelp()">Ayuda</button></small>
    </h1>
    <div>
      
    </div>
    <ol class="breadcrumb">
     
      <li><a href="#"><i class="fa fa-dashboard"></i> Menu</a></li>
      <li class="active">Socios</li>
    </ol>
  </section>

  <!-- Main content -->
  <section class="content" >

    <div class="row">
      <div class="col-sm-12">
        <div class="box">
          <div class="box-header">
            <div class="row">
              <div class="col-sm-8">
                 <pagination-controls (pageChange)="p = $event" 
                  autoHide="true"
                          responsive="true"
                  previousLabel="Anterior"
                  nextLabel="Próxima">
                    </pagination-controls>
              </div>
              <div class="col-sm-4">
                <button class="btn btn-outline-success text-right btn-sm" style="float: right"
                  (click)="exportAsXLSX()"><i style="margin-right: 5px;" class="fa fa-download"></i>Exportar</button>
             
            <button *ngIf="btnNew" class="btn btn-outline-success text-right btn-sm" style="float: right"
              (click)="onModalRequest()"><i style="margin-right: 5px;" class="fa fa-plus"></i>Agregar</button>
          </div>

            </div>
          </div>

          <div class="box-body">

            <table class="table table-bordered table-hover table-sm table-condensed">
              <thead>
                <tr>
                  <th scope="col">#</th> 
                  <th scope="col" class="d-none d-lg-table-cell">Rut</th>
                  <th scope="col" class="d-none d-lg-table-cell">Fec. Reg.</th>
                  <th scope="col">Nombre</th>
                  <th scope="col" class="d-none d-lg-table-cell">Rol</th>
                  <th scope="col" class="d-none d-lg-table-cell">Dirección</th>
                  <th scope="col" class="d-none d-lg-table-cell">Fec. Nac.</th>
                  <th scope="col">Email</th>
                  <th scope="col">Teléfono</th>
                  <!-- <th scope="col" class="d-none d-lg-table-cell">Profesión</th> -->
                  <th scope="col">Acción</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let socio of socios| paginate: { itemsPerPage: 9, currentPage: p }">
                  <td>{{socio.numero}}</td> 
                  <td class="d-none d-lg-table-cell">{{socio.rut}}-{{socio.dv}}</td>
                  <td class="d-none d-lg-table-cell">{{socio.fecreg}}</td>
                  <td>{{socio.nombre}}</td>
                  <td class="d-none d-lg-table-cell">{{socio.rol.nombre}}</td>
                  <td class="d-none d-lg-table-cell">{{socio.direccion}}</td>
                  <td class="d-none d-lg-table-cell">{{socio.fecnac}}</td>
                  <td>{{socio.email}}</td>
                  <td>{{socio.telefono}}</td>
                  <!-- <td class="d-none d-lg-table-cell">{{socio.profesion}}</td> -->
                  <td>

                    <button type="button" class="btn text-primary btn-sm d-none d-lg-table-cell accion" 
                      (click)="onModalView(socio.id)"><i class="fa fa-eye"></i></button>
                    <button *ngIf="btnEdit" type="button" class="btn text-success btn-sm accion"  (click)="onModalEdit(socio.id)"><i
                        class="fa fa-edit"></i></button>
                    <button *ngIf="btnDel" type="button" class="btn text-danger  btn-sm accion" (click)="deleteSocio(socio)"><i
                        class="fa fa-trash"></i></button>

                  </td>
                </tr>

              </tbody>
            </table>
            <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
              <p style="color: white">Cargando datos... </p>  
              </ngx-spinner>   
          </div>
        </div>
      </div>
    </div>


  </section>
  <!-- /.content -->
