import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HelpService } from 'src/app/services/help.service';
import { Help } from 'src/app/modules/help';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {
  @Input() id: number;
   titulo:string;
   theHtmlString: string;
   help :Help;
   nombre:string;
   texto:string;
  router: any;
  constructor(private helpService: HelpService, private sanitizer: DomSanitizer,public activeModal: NgbActiveModal) { }

  ngOnInit(): void {

    this.listId();
  
   
  }

  listId() {
 
    this.helpService.listId(this.id)
      .subscribe(data => {
        this.help=data.result
            this.nombre=this.help.nombre;
            this.texto=this.help.texto;
        /*302 token expirado*/
        if (data.status == 302) {
          this.router.navigate(['/login']);
          return;
        }
      },
      error => {
        this.router.navigate(['login']);
        return;
      },
      );

  }

  onClose(result: boolean): void {
    this.activeModal.close(result);
  }

  onDismiss(reason: String): void {
    this.activeModal.dismiss(reason);
  }
}
