<!-- Content Header (Page header) -->
<section class="content-header">
    <h1>
        Mi Organización
        <small style="float: right;margin-right: 155px"><button type="button" class="btn text-primary btn-sm d-none d-lg-table-cell accion" 
            (click)="onModalHelp()">Ayuda</button></small>
    </h1>
    <ol class="breadcrumb">
        <li><a href="#"><i class="fa fa-dashboard"></i> Menu</a></li>
        <li class="active">Mi Organización</li>
    </ol>
</section>

<!-- Main content -->
<section class="content" style="padding-left: 30px;">

    <div class="row">
        <div class="col-sm-12">
            <div class="box">
                <div class="box-header">
                </div>
                <div class="box-body">
                    <div class="row">
                        <div class="col-md-4">
                            <!-- Profile Image -->
                            <div class="box box-primary">
                                <div class="box-body box-profile">
                                    <img class="profile-user-img img-responsive"
                                        style="border-radius: 50%;display: block" src="assets/dist/img/avatar-png-7.png"
                                        alt="User profile picture">

                                    <h3 class="profile-username text-center">{{autoridad.presnombre}} {{autoridad.presapellidos}}</h3>

                                    <p class="text-muted text-center">Presidente</p>

                                </div>
                                <!-- /.box-body -->
                            </div>
                            <!-- /.box -->
                        </div>
                        <div class="col-md-4">
                            <!-- Profile Image -->
                            <div class="box box-primary">
                                <div class="box-body box-profile">
                                    <img class="profile-user-img img-responsive"
                                        style="border-radius: 50%;display: block" src="assets/dist/img/avatar-png-7.png"
                                        alt="User profile picture">

                                    <h3 class="profile-username text-center">{{autoridad.tesnombre}} {{autoridad.tesapellidos}}</h3>

                                    <p class="text-muted text-center">Tesorero</p>

                                </div>
                                <!-- /.box-body -->
                            </div>
                            <!-- /.box -->


                        </div>
                        <div class="col-md-4">
                            <!-- Profile Image -->
                            <div class="box box-primary">
                                <div class="box-body box-profile">
                                    <img class="profile-user-img img-responsive"
                                        style="border-radius: 50%;display: block" src="assets/dist/img/avatar-png-7.png"
                                        alt="User profile picture">

                                    <h3 class="profile-username text-center">{{autoridad.secnombre}} {{autoridad.secapellidos}}</h3>

                                    <p class="text-muted text-center">Secretario(a)</p>

                                </div>
                                <!-- /.box-body -->
                            </div>
                            <!-- /.box -->

                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <!-- About Me Box -->
                            <div class="box box-primary">

                                <!-- /.box-header -->
                                <div class="box-body">
                                    <strong><i class="fa fa-book margin-r-5"></i> Tipo de Organización</strong>
                                    <p class="text-muted">
                                        {{organizacion.tipo.nombre}}
                                    </p>
                                    <hr>
                                    <strong><i class="fa fa-phone margin-r-5"></i> Teléfono</strong>
                                    <p class="text-muted">{{organizacion.telefono}}</p>
                                </div>
                                <!-- /.box-body -->
                            </div>
                            <!-- /.box -->
                        </div>
                        <div class="col-md-4">
                            <!-- About Me Box -->
                            <div class="box box-primary">

                                <!-- /.box-header -->
                                <div class="box-body">
                                    <strong><i class="fa fa-map-marker margin-r-5"></i> Dirección</strong>
                                    <p class="text-muted">{{organizacion.direccion}},{{organizacion.comuna.nombre}}</p>
                                    <hr>
                                    <strong><i class="fa fa-envelope margin-r-5"></i> Email</strong>
                                    <p class="text-muted">{{organizacion.email}}</p>
                                </div>
                                <!-- /.box-body -->
                            </div>
                            <!-- /.box -->
                        </div>
                        <div class="col-md-4">
                            <!-- About Me Box -->
                            <div class="box box-primary">

                                <!-- /.box-header -->
                                <div class="box-body">
                                    <strong><i class="fa fa-id-badge margin-r-5"></i> Rut</strong>
                                    <p class="text-muted">{{organizacion.rut}}-{{organizacion.dv}}</p>
                                    <hr>
                                    <strong><i class="fa fa-usd margin-r-5"></i> Cuota</strong>
                                    <p class="text-muted">{{cuota.id | number}} / {{cuota.nombre}}</p>
                                </div>
                                <!-- /.box-body -->
                            </div>
                            <!-- /.box -->
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>
<!-- /.content -->