import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Ingreso } from 'src/app/modules/ingreso';
import { Proyecto } from 'src/app/modules/proyecto';
import { Observable } from 'rxjs';
import { NotificacionService } from 'src/app/services/notificacion.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IngresoService } from 'src/app/services/ingreso.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ProyectoService } from 'src/app/services/proyecto.service';
import { TipoitemService } from 'src/app/services/tipoitem.service';
import { Tipoitem } from 'src/app/modules/tipoitem';
import { ViewDocComponent } from 'src/app/components/shared/view-doc/view-doc.component';
import { AuthService } from 'src/app/auth/auth.service';
import { GlobalConstants } from 'src/app/modules/global-constants';
import * as _ from 'lodash';

@Component({
  selector: 'app-create-ingreso',
  templateUrl: './create-ingreso.component.html',
  styleUrls: ['./create-ingreso.component.css'],
  providers: [DatePipe]
})
export class CreateIngresoComponent implements OnInit {

  @Input() public id: number;
  @Input() public accion: string;
  formIngreso: FormGroup;
  submitted = false;
  ingresos: Observable<Ingreso[]>;
  proyectos: Observable<Proyecto[]>;
  items: Observable<Tipoitem[]>;
  isReadOnly: boolean;
  conDoc: boolean=false;
  verDoc: boolean=false;
  mime: string;
  imageError:string;
  constructor(private dataService: AuthService,private notificacion: NotificacionService, private formBuilder: FormBuilder, private modalService: NgbModal,public activeModal: NgbActiveModal, private ingresoService: IngresoService, private router: Router, private datePipe: DatePipe, private elem: ElementRef, private proyectoService: ProyectoService, private tipoitemService: TipoitemService) { }

  ngOnInit() {

    this.formIngreso = this.formBuilder.group({
      fecha: ['', Validators.required],
      monto: ['', Validators.compose([
        Validators.required,
        Validators.min(1),
        Validators.max(1000000000), 
        Validators.pattern(/\d/)])
      ],
      item: ['', Validators.required],
      proyecto: ['', Validators.required],
    
      obs: ['', Validators.compose([
        Validators.required,
        Validators.maxLength(1000)])
      ],
      archivo: ['']

    });
    this.listProyectos();
    this.listItems();
   
    

    if (this.id != -1) {

      this.listIdIngreso(this.id);
    }
    this.isReadOnly = this.accion == 'Ver';
    if (this.isReadOnly) {
    
      this.formIngreso.get('fecha').disable();
      this.formIngreso.get('monto').disable();
      this.formIngreso.get('item').disable();
      this.formIngreso.get('proyecto').disable();
     
      this.formIngreso.get('obs').disable();
    }

  }

  listProyectos() {
    this.proyectoService.listProyectos()
      .subscribe(data => {

        this.proyectos = data.result;
        console.log(this.proyectos);
        /*302 token expirado*/
        if (data.status == 302) {
          this.router.navigate(['login']);
          return;
        }
      });

  }

  listItems() {
    this.tipoitemService.listTipoItems(1)
      .subscribe(data => {
        this.items = data.result;
      });
  }

  crearIngreso(rIngreso: any) {
    this.submitted = true;
    if (this.formIngreso.invalid) {
      return;
    }
    const ingreso = new Ingreso();
    ingreso.id = this.id;
    ingreso.monto = rIngreso.monto;
    ingreso.proyecto = rIngreso.proyecto;
    ingreso.items = rIngreso.item;
    //ingreso.doc =rIngreso.item;
    ingreso.estado =1;
    ingreso.obs = rIngreso.obs;
    ingreso.conDoc=this.conDoc;
    ingreso.fecha = new Date(rIngreso.fecha.year, rIngreso.fecha.month - 1, rIngreso.fecha.day);
    let currentUser = this.dataService.currentUserValue;
    ingreso.usuario=currentUser.user[0].username;

    const formData = new FormData();
    formData.append('archivo', this.formIngreso.get('archivo').value);
    formData.append('ingreso',JSON.stringify(ingreso))
    
    this.ingresoService.createIngreso(formData).subscribe(data => {
      this.notificacion.showSuccess(data.message, "Info")
      this.onClose(true);

    }, (err) => {
      this.notificacion.showError(err.message, "Advertencia");
     }
    );
  }

  fileProgress(event: any) {
    if (event.target.files.length > 0) {

      if (event.target.files[0].size > GlobalConstants.max_size) {
        this.imageError =
            'El tamaño máximo permitido es ' + GlobalConstants.max_size / 1048576 + 'Mb';
        return false;
    }
    if (!_.includes(GlobalConstants.allowed_types, event.target.files[0].type)) {
        this.imageError = 'Solo se permiten archivos( JPG | PNG | PDF )';
        return false;
    }
    
      const file = event.target.files[0];
      this.formIngreso.get('archivo').setValue(file);
      this.conDoc=true;
    }
   
  }

  listIdIngreso(id: number) {
    this.ingresoService.listIdIngreso(id)
      .subscribe(data => {
        this.ingresos = data.result;

        this.formIngreso.patchValue(this.ingresos);

        const aaaa = Number(this.datePipe.transform(data.result.fecha, 'yyyy'));
        const mm = Number(this.datePipe.transform(data.result.fecha, 'MM'));
        const dd = Number(this.datePipe.transform(data.result.fecha, 'dd'));
        this.formIngreso.controls.fecha.setValue({ year: aaaa, month: mm, day: dd });

        this.formIngreso.controls.proyecto.patchValue(data.result.proyecto[0].id);
        this.formIngreso.controls.item.patchValue(data.result.items[0].id);
        this.verDoc= data.result.doc>0;
        this.mime=data.result.mime;
      });

  }

  onModalViewDoc():void {
    const modalRef = this.modalService.open(ViewDocComponent, { size: 'md' } );
    modalRef.componentInstance.id = this.id;
    modalRef.componentInstance.tipo = 1;
    modalRef.componentInstance.mime = this.mime;
    modalRef.result.then((result) => {
         
    }).catch( (result) => {
    console.log(result);
    });
    }

  onClose(result: boolean): void {
    this.activeModal.close(result);
  }

  onDismiss(reason: String): void {
    this.activeModal.dismiss(reason);
  }

  get f() { return this.formIngreso.controls; }
}
