import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html'
})
export class MenuComponent implements OnInit {
  nombre:string;
  tipo: number;
  mSocio:boolean;
  mNoticia:boolean;
  mItem:boolean;
  mProyecto:boolean;
  mIngreso:boolean;
  mEgreso:boolean;
  mActa:boolean;
  mResidencia:boolean;
  mCuota:boolean;
  mOrganizacion:boolean;
  mTablero:boolean;
  constructor(private dataService: AuthService) { }

  ngOnInit() {
    const currentUser = this.dataService.currentUserValue;
    if (currentUser) {
      this.nombre=currentUser.user[0].nombre;
      this.tipo=currentUser.user[0].tipo; 
     currentUser.user[0].menu.forEach(element => {
         if (element.nombre=='Socio'){
        this.mSocio=element.menu==1; 
       }
       if (element.nombre=='Tablero'){
        this.mTablero=element.menu==1; 
       }
       if (element.nombre=='Cuota'){
        this.mCuota=element.menu==1; 
       }
       if (element.nombre=='Residencia'){
        this.mResidencia=element.menu==1; 
       }
       if (element.nombre=='Ingreso'){
        this.mIngreso=element.menu==1; 
       }
       if (element.nombre=='Egreso'){
        this.mEgreso=element.menu==1; 
       }
       if (element.nombre=='Proyecto'){
        this.mProyecto=element.menu==1; 
       }
       if (element.nombre=='Item'){
        this.mItem=element.menu==1; 
       }
       if (element.nombre=='Noticia'){
        this.mNoticia=element.menu==1; 
       }
       if (element.nombre=='Organizacion'){
        this.mOrganizacion=element.menu==1; 
       }
       if (element.nombre=='Acta'){
        this.mActa=element.menu==1; 
       }

});

   /*  this.mSocio=currentUser.user[0].menu.socio==1; 
     this.mTablero=currentUser.user[0].menu.tablero==1; 
     this.mResidencia=currentUser.user[0].menu.residencia==1; 
     this.mActa=currentUser.user[0].menu.acta==1; 
     this.mIngreso=currentUser.user[0].menu.ingreso==1; 
     this.mEgreso=currentUser.user[0].menu.egreso==1; 
     this.mNoticia=currentUser.user[0].menu.noticia==1; 
     this.mItem=currentUser.user[0].menu.item==1; 
     this.mProyecto=currentUser.user[0].menu.proyecto==1; 
     this.mOrganizacion=currentUser.user[0].menu.organizacion==1; 
     this.mCuota=currentUser.user[0].menu.cuota==1 ;
     */
    }
  }

}
