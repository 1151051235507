import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-home-layout',
  templateUrl: './home-layout.component.html'
})
export class HomeLayoutComponent implements OnInit, OnDestroy {

  constructor() { }

  ngOnInit() {
    window.dispatchEvent(new Event('resize'));
    document.body.className="hold-transition skin-blue sidebar-mini";
  }

  ngOnDestroy(): void {
    document.body.className="";
  }

}
