import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse } from '../modules/apiresponse';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { GlobalConstants } from '../modules/global-constants';

@Injectable({
  providedIn: 'root'
})
export class ProyectoService {
  baseUrl:string = GlobalConstants.apiURL;
  currentUser = this.dataService.currentUserValue;
  constructor(private httpClient: HttpClient, private dataService: AuthService ) { }

  public listProyectos() : Observable<ApiResponse>{
    return this.httpClient.get<ApiResponse>(this.baseUrl +'/listProyectos.php');
  }

  public listIdProyecto(Id: number) : Observable<ApiResponse>{
    return this.httpClient.get<ApiResponse>(this.baseUrl +'/listIdProyecto.php?id='+Id);
  }
  public createProyecto (formData: any): Observable<ApiResponse> {
      return this.httpClient.post<ApiResponse>(this.baseUrl +'/createProyecto.php', formData)
  }

  public deleteProyecto(id: number): Observable<ApiResponse> {
    return this.httpClient.get<ApiResponse>(this.baseUrl +'/deleteProyecto.php?id='+id+'&usuario='+this.currentUser.user[0].username);
  }
}
