import { Component, OnInit, Input, Directive } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators, NgControl } from '@angular/forms';
import { SocioService } from '../../services/socio.service';
import { Router } from '@angular/router';
import { Socio } from 'src/app/modules/socio';
import { NotificacionService } from '../../services/notificacion.service';
import { Observable } from 'rxjs';
import {  DatePipe, SlicePipe } from '@angular/common';
import { Tipo } from 'src/app/modules/tipo';
import { TipoService } from 'src/app/services/tipo.service';
import { RutValidator } from 'ng2-rut';
import { AuthService } from 'src/app/auth/auth.service';



@Component({
  selector: 'app-addsocio',
  templateUrl: './create-socio.component.html',
  styleUrls: ['./create-socio.component.css'],
  providers: [DatePipe]
})


export class CreateSocioComponent implements OnInit {
  @Input() public id;
  @Input() public accion;
  isReadOnly: boolean = false;
  registerSocio: FormGroup;
  submitted = false;
  socios: Observable<Socio[]>;
  socio:Socio;
  rolSocio: Observable<Tipo[]>;
  checked : boolean=false ;
  rol:number;
  
  constructor(private dataService: AuthService,private rv:RutValidator,private notificacion: NotificacionService, private formBuilder: FormBuilder, public activeModal: NgbActiveModal, private socioService: SocioService, private router: Router, private datePipe: DatePipe, private tipoService: TipoService) { }

  ngOnInit() {
    this.rol = this.dataService.currentUserValue.user[0].rolid;


    this.registerSocio = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      //email: ['' ],
      rut: ['', [this.rv]],
      // dv: ['', Validators.compose([
      //   Validators.maxLength(1),
      //   Validators.pattern('[kK0-9]*')])
      // ],
      nombre: ['', Validators.compose([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(100),])
      ],
      apellidos: ['', Validators.compose([
        Validators.required,
        Validators.maxLength(100),])
      ],
      profesion: ['', Validators.maxLength(100)],
      direccion: ['', Validators.compose([
        Validators.required,
        Validators.maxLength(500),])
      ],
      ciudad: ['', Validators.compose([
        Validators.required,
        Validators.maxLength(100),])
      ],
      // estado: ['', Validators.required],
      rol: ['', Validators.required],
      obs: ['', Validators.maxLength(1000)],
      fecnac: ['', Validators.required],
      fecreg: ['', Validators.required],
      telefono: ['', Validators.compose([
        Validators.required,
        Validators.minLength(7),
        Validators.maxLength(9),
        Validators.pattern(/\d/)])
      ],
      select: [''],
      numero: ['', Validators.compose([
        Validators.required,
        Validators.min(1),
        Validators.max(1000000000), 
        Validators.pattern(/\d/)])
      ],
    });
    
    this.listRolSocio();
    this.registerSocio.get('rut').disable();
    // this.registerSocio.controls.estado.setValue(1);
    
    if (this.id != -1) {

      this.listIdSocios(this.id);
    }
    this.isReadOnly = this.accion == 'Ver';
    if (this.isReadOnly) {
      this.registerSocio.get('email').disable();
      this.registerSocio.get('rut').disable();
      this.registerSocio.get('numero').disable();
      this.registerSocio.get('nombre').disable();
      this.registerSocio.get('apellidos').disable();
      this.registerSocio.get('profesion').disable();
      this.registerSocio.get('direccion').disable();
      this.registerSocio.get('ciudad').disable();
      // this.registerSocio.get('estado').disable();
      this.registerSocio.get('rol').disable();
      this.registerSocio.get('obs').disable();
      this.registerSocio.get('fecnac').disable();
      this.registerSocio.get('fecreg').disable();
      this.registerSocio.get('telefono').disable();
     
    }
    else {
      this.registerSocio.controls.rol.patchValue(4);
      console.log(this.rol);
      if (this.rol!=1) {
      this.registerSocio.get('rol').disable();
      }
    }
   
  }

  listRolSocio() {
    this.tipoService.listRolSocio()
      .subscribe(data => {

        this.rolSocio = data.result;
     
        /*302 token expirado*/
        if (data.status == 302) {
          this.router.navigate(['login']);
          return;
        }
      });

  }

  crearSocio(rSocio: any) {

  
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerSocio.invalid) {
      return;
    }
    const socio = new Socio();
    socio.id = this.id;
    socio.apellidos = rSocio.apellidos;
    socio.email = rSocio.email;
    socio.numero= rSocio.numero
    if (this.checked) {
      socio.rut =  rSocio.rut
      let guion =  socio.rut.includes("-");
    if (!guion) {
      socio.dv = socio.rut.substr(socio.rut.length-1,1)
      socio.rut = socio.rut.substr(0,socio.rut.length-1)
    }
    else {
      socio.dv = socio.rut.substr(socio.rut.length-1,1)
      socio.rut = socio.rut.substr(0,socio.rut.length-2)
    }
    }
    else {
      socio.rut =null;
    socio.dv = null;
    }
    socio.nombre = rSocio.nombre;
    socio.profesion = rSocio.profesion;
    socio.direccion = rSocio.direccion;
    if (rSocio.rol==undefined){
        socio.rol = 4;
    }
    else{
      socio.rol = rSocio.rol;
    }
    socio.ciudad = rSocio.ciudad;
   socio.estado = 1; 
   
    socio.obs = rSocio.obs;
    socio.telefono = rSocio.telefono;

    socio.fecnac = new Date(rSocio.fecnac.year, rSocio.fecnac.month - 1, rSocio.fecnac.day);
    socio.fecreg = new Date(rSocio.fecreg.year, rSocio.fecreg.month - 1, rSocio.fecreg.day);

    let currentUser = this.dataService.currentUserValue;
    socio.usuario=currentUser.user[0].username;
console.log(socio);
    this.socioService.createSocio(socio)
      .subscribe(data => {
        this.notificacion.showSuccess(data.message, "Info")
        this.onClose(true);
      }, (err) => {
        console.log(err);
      }
      );
  }

  listIdSocios(id: number) {
    this.socioService.listIdSocios(id)
      .subscribe(data => {
        this.socio = data.result;
        let rolid=data.result.rol.id;
       
        this.registerSocio.patchValue(this.socio);

        this.registerSocio.controls.rut.patchValue(this.socio.rut+'-'+this.socio.dv)
        const aaaa = Number(this.datePipe.transform(this.socio.fecnac, 'yyyy'));
        const mm = Number(this.datePipe.transform(this.socio.fecnac, 'MM'));
        const dd = Number(this.datePipe.transform(this.socio.fecnac, 'dd'));
        this.registerSocio.controls.fecnac.setValue({ year: aaaa, month: mm, day: dd });

        const aaaa2 = Number(this.datePipe.transform(this.socio.fecreg, 'yyyy'));
        const mm2 = Number(this.datePipe.transform(this.socio.fecreg, 'MM'));
        const dd2 = Number(this.datePipe.transform(this.socio.fecreg, 'dd'));
        this.registerSocio.controls.fecreg.setValue({ year: aaaa2, month: mm2, day: dd2 });
        this.registerSocio.controls.rol.patchValue(rolid);
        if (this.socio.rut!='0'){
        this.registerSocio.controls.select.patchValue(true);
        this.changeStatus(true)
        }
      });
  }

  onClose(result: boolean): void {
    this.activeModal.close(result);
  }

  onDismiss(reason: String): void {
    this.activeModal.dismiss(reason);
  }

  get f() { return this.registerSocio.controls; }

  changeStatus(isChecked){
    if (!this.isReadOnly){
    this.checked=isChecked;
   if (isChecked){
    this.registerSocio.get('rut').enable();
   }
   else {
    this.registerSocio.get('rut').disable();
   }
  }  
  }
}
